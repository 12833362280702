// AMN - here we put all the configuration variables. Not all the vars
// only the ones that are core and/or we use in different places so we
// can change them in any moment if we wanted/needed to.

// Capital letter 'cos is a constant that will never change. Anyway, in case
// that for whatever reason we wanted to change them we could do it
// by changing only it here and it would be automatically changed in 
// all the places used in the code. 

export const MONTHS_OFFSET = 1; // new Date() gives you month starting from 0 till 11 (jan = 0)

// Keep this array updated adding and removing n:m table names  as you create or delete objectives-related-tables on DDBB
// The nested arrays names on positions 1 and 2 MUST match the names expected by the API when call for inserting or updating the tables.
export const N_M_OBJ_TABLES = [['brand', 'insertProductBrandIds', 'deleteProductBrandIds'], 
                                                             ['businessunit', 'insertProductBusinessUnitIds', 'deleteProductBusinessUnitIds'], 
                                                             ['distributor', 'insertDistributorIds', 'deleteDistributorIds'], 
                                                             ['group', 'insertProductGroupIds', 'deleteProductGroupIds'], 
                                                             ['postalcode', 'insertPostalCodeIds', 'deletePostalCodeIds'], 
                                                             ['product', 'insertProductTenantIds', 'deleteProductTenantIds'], 
                                                             ['set', 'insertProductSetIds', 'deleteProductSetIds'],
                                                             ['country', 'insertCountryNames', 'deleteCountryNames'],
                                                             ['region', 'insertRegionNames', 'deleteRegionNames'],
                                                             ['province', 'insertProvinceNames', 'deleteProvinceNames']]

export const CURRENCY_FORMAT = new Intl.NumberFormat('sp-EU')
//export const CURRENCY_FORMAT = new Intl.NumberFormat('sp-EU', { style: 'currency', currency: 'EUR' })

export const MONTHS = {
    1: 'january',
    2: 'february',
    3: 'march',
    4: 'april',
    5: 'may',
    6: 'june',
    7: 'july',
    8: 'august',
    9: 'september',
    10: 'october',
    11: 'november',
    12: 'december'
}

export const MONTHS_ERRORS = {
    1: 'hasJanuaryErrors',
    2: 'hasFebruaryErrors',
    3: 'hasMarchErrors',
    4: 'hasAprilErrors',
    5: 'hasMayErrors',
    6: 'hasJuneErrors',
    7: 'hasJulyErrors',
    8: 'hasAugustErrors',
    9: 'hasSeptemberErrors',
    10: 'hasOctoberErrors',
    11: 'hasNovemberErrors',
    12: 'hasDecemberErrors'
}

export const AGREEMENT_VALUE_TYPES = [
    'dosis',
    'euros',
    'unidades'
]
// reports' filters: position of the elements within the array of arrays
export const FROMTYPEOFSELECTIONTOARRAYPOSITION = {
    Regions: 0,
    Provinces: 1,
    Populations: 2,
    PostalCodes: 3, 
    Brands: 4,
    Groups: 5,
    BusinessUnits: 6,
    Sets: 7,
    CIFs: 8,
    ClientCategories: 9,
}

// reports' filters: elements type depending of their position within the array of arrays
export const FROMARRAYPOSITIONTOTYPEOFSELECTION = {
    0: 'region',
    1: 'province',
    2: 'population',
    3: 'postalcode', 
    4: 'brand',
    5: 'group',
    6: 'businessunit',
    7: 'set',
    8: 'clientidentity',
    9: 'clientcategory',
}

// reports' filters: array or arrays elements from plural to singular
// the elements are named in plural on the component but singular on the DDBB
export const FROMPLURALTYPEOFSELECTIONTOSINGULAR = {
    Regions: 'region',
    Provinces: 'province',
    Populations: 'population',
    PostalCodes: 'postalcode', 
    Brands: 'brand',
    Groups: 'group',
    BusinessUnits: 'businessunit',
    Sets: 'set',
    CIFs: 'clientidentity',
    ClientCategories: 'clientcategory'
}

// Checking max date (month and year close to today) that the user can block the reports' upload
export const MAXMONTHTOBLOCK = {
    0: new Date().getFullYear()-1 + '-' + '11', // Jan --> Max Nov previous year
    1: new Date().getFullYear()-1 + '-' + '12', // Feb --> Max Dec previous year
    2: new Date().getFullYear() + '-' + '01', // Mar --> Max Jan current year
    3: new Date().getFullYear() + '-' + '02', // Abr --> Max Feb current year
    4: new Date().getFullYear() + '-' + '03', // May --> Max Mar current year
    5: new Date().getFullYear() + '-' + '04', // Jun --> Max Abr current year
    6: new Date().getFullYear() + '-' + '05', // Jul --> Max May current year
    7: new Date().getFullYear() + '-' + '06', // Aug --> Max Jun current year
    8: new Date().getFullYear() + '-' + '07', // Sep --> Max Jul current year
    9: new Date().getFullYear() + '-' + '08', // Oct --> Max Aug current year
    10: new Date().getFullYear() + '-' + '09', // Nov --> Max Sep current year
    11: new Date().getFullYear() + '-' + '10', // Dec --> Max Oct current year
}

// AMN Note: important, the messages recorded in the audit trail for reports downloaded by tenants MUST follow a pattern otherwise the Analytics+ report
// generated for the ADMIN user might fail. Check Analytics+ report where we show the number or reports requested by tenants.
// pattern expected in the message is:
// - the name of the report between 'Requested the ' and ' report'
// - the period requested between 'from ' and ' by'
// - the id of the tenant after 'tenant with id: '
// - the year of the price applied when there are values in the report (not mandatory)
// Respect this pattern and avoid using the key words expected in the message to avoid errors due to key words duplication

export const AUDITTRAILREPORTMSG = (reportName, reportPeriod, tenantId, yearPrice = 0) => {
    const yearPriceApplied = yearPrice !== 0 ? `. Year price applied: ${yearPrice}` : ''
    return `Requested the ${reportName} report for the period from ${reportPeriod} by tenant with id: ${tenantId}${yearPriceApplied}`
}

export const CURRENCIES = ['EUR']