import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TabsSales } from '../tabsSales'
import { YearPicker } from '../yearPicker'
import { Box, Level } from 'rbx'
import { Table, Icon, Tabs, Button, Popover, Input } from 'antd'
import { EditableFormRow, EditableCell } from './editable'
import { useAlert } from 'react-alert'
import { AdsContext } from "../../../contexts/adsContext"
import { MONTHS, AUDITTRAILREPORTMSG } from '../../../config/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faStickyNote, faFlagCheckered } from '@fortawesome/free-solid-svg-icons'
import { logic } from '../../../logic'
import Highlighter from 'react-highlight-words'
const { TabPane } = Tabs

export const ObjectivesSales = (props) => {
  let newRows = []
  const alert = useAlert()

  const [hasRegistries, setHasRegistries] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
   // Flag to hide contents based on whether a provider/distributor(s) and a year has been selected
  const [isHidden, setIsHidden] = useState(true)
  const [selectedTenant, setSelectedTenant] = useState()
  const [selectedTenantName, setSelectedTenantName] = useState()
  const [selectedDistributors, setSelectedDistributors] = useState()
  const [selectedTypes, setSelectedTypes] = useState()
  const [searchInput, setSearchInput] = useState()
  const [searchedColumn, setSearchedColumn] = useState('')
  const [searchText, setSearchText] = useState('')
  const [isSavingData, setIsSavingData] = useState(false)

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const [shownYear, setShownYear] = useState(new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear())
  const { allDistributorObjectives, allDistributorTenantObjectives, fetchAllDistributorObjectives, 
              fetchAllDistributorTenantObjectives, insertDistTenantObj, updateDistTenantObj, updateObjDistributor,
              downloadAllDistributorsTenantSales } = useContext(AdsContext)
  
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            setSearchInput(node)
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record = '') => 
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()) ||
      record[dataIndex === 'objective_name' ? 'objective_code' : 'clienttenant_name']
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()) ||
      record[ 'clients_CIFs'] 
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()) 
    ,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  })
           
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }
  
  const popOver = (objData) => {
    return objData !== 'n/a' ? (
      <div>
        <p><strong>{t('code')}:</strong> {objData.objectivecode}</p>
        <p><strong>{t('name')}:</strong> {objData.objectivename}</p>
        <p><strong>{t('agreement_name')}:</strong> {objData.agreementtypename}</p>
        {logic.isTenant ? <p><strong>{t('comments')}:</strong> {objData.objectivedescription}</p> : null}
        <p><strong>{t('Desde')}:</strong> {`${new Date(objData.starttime).getDate().toString().padStart(2, '0')}/${(new Date(objData.starttime).getMonth()+1).toString().padStart(2, '0')}/${new Date(objData.starttime).getFullYear()}`}</p>
        <p><strong>{t('Hasta')}</strong> {`${new Date(objData.endtime).getDate().toString().padStart(2, '0')}/${(new Date(objData.endtime).getMonth()+1).toString().padStart(2, '0')}/${new Date(objData.endtime).getFullYear()}`}</p>
        <p><strong>{t('value')}:</strong> {`${objData.totalvalueobjective.toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${t(objData.valuetype)}`}</p>
        {!logic.isTenant && !logic.isAdmin ? <p><strong>{t('clients')}:</strong> </p> : null}
        {!logic.isTenant && !logic.isAdmin && objData.client.filter((client, index) => client !== null)
                                                                                                  .map((client, index) => {
                                                                                                    return (
                                                                                                      <p key={index}>- {client}</p>
                                                                                                    )
                                                                                                  })
        }
        <p><strong>{t(' Business Units')}:</strong> </p>
        {objData.businessunit.map((businessunit, index) => {
          return (
            <p key={index}>- {businessunit}</p>
          )
        })}
        <p><strong>{t(' Brands')}:</strong> </p>
        {objData.brand.map((brand, index)=> {
          return (
            <p key={index}>- {brand}</p>
          )
        })}
        <p><strong>{t(' Groups')}:</strong> </p>
        {objData.group.map((group, index)=> {
          return (
            <p key={index}>- {group}</p>
          )
        })}
        <p><strong>{t(' Sets')}:</strong> </p>
        {objData.set.map((set, index)=> {
          return (
            <p key={index}>- {set}</p>
          )
        })}
        <p><strong>{t(' popUpProducts')}:</strong> </p>
        {objData.product.map((product, index) => {
          return (
            <p key={index}>- {product}</p>
          )
        })}
        <p><strong>{t('Countries')}:</strong> </p>
        {objData.country.map((country, index) => {
          return (
            <p key={index}>- {country}</p>
          )
        })}
        <p><strong>{t('Regions')}:</strong> </p>
        {objData.region.map((region, index) => {
          return (
            <p key={index}>- {region}</p>
          )
        })}
        <p><strong>{t('Provinces')}:</strong> </p>
        {objData.province.map((province, index) => {
          return (
            <p key={index}>- {province}</p>
          )
        })}
        <p><strong>{t('Postal codes')}:</strong> </p>
        {objData.postalcode.map((postalcode, index) => {
          return (
            <p key={index}>- {postalcode}</p>
          )
        })}
      </div>
    ) : (<div>
            <p>{t('Aggregatted imports per month')}</p>
          </div>)
  } 
  const [dataSource, setDataSource] = useState([])
  const [totalTotals, setTotalTotals] = useState([])

  const [columnsTotalTotals, setColumnsTotalTotals] = useState ([
    logic.isTenant ? 
    {
      title: t('distributor'),
      dataIndex: 'distributor_name',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  (
        <span>
            <Popover content={record.distributor_name}>
              {record.distributor_name.length > 15 ? record.distributor_name.slice(0,11)+"..." : record.distributor_name.slice(0,14)}
            </Popover>
        </span>),
    } : 
    {
      title: t('agreement_name'),
      dataIndex: 'agreement_name',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  (
        <span>
            <Popover content={record.agreement_name}>
              {record.agreement_name.length > 15 ? record.agreement_name.slice(0,11)+"..." : record.agreement_name.slice(0,14)}
            </Popover>
        </span>),
    },
    logic.isTenant ? 
    {
      title: t('tenant_agreement_code'),
      dataIndex: 'tenant_agreement_code',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  record.objective_description !== ' ' ?
      (
        <span>
            <Popover content={record.objective_code}>
              {record.objective_code.length > 15 ? record.objective_code.slice(0,11) + " " + "..." : record.objective_code.slice(0,14) + " "}
            </Popover>
          <a>
            <Popover placement="rightTop" content={`${record.objective_description}`}>
              
                <Icon>
                  <FontAwesomeIcon icon={faStickyNote} />
                </Icon>
                
            </Popover>
          </a>
        </span>
      ) :
      (
        <span>
          <Popover content={record.objective_code}>
            {record.objective_code.length > 15 ? record.objective_code.slice(0,11) + " " + "..." : record.objective_code.slice(0,14) + " "}
          </Popover>
        </span>
      ),
    }
    :
    {
      title: t('tenant_agreement_code'),
      dataIndex: 'tenant_agreement_code',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  
      (
        <span>
            <Popover content={record.objective_code}>
              {record.objective_code.length > 15 ? record.objective_code.slice(0,11) + " " + "..." : record.objective_code.slice(0,14) + " "}
            </Popover>
        </span>
      ),
    },
    logic.isTenant ? 
    {
      title: t('agreement_name'),
      dataIndex: 'agreement_name',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  (
        <span>
            <Popover content={record.agreement_name}>
              {record.agreement_name.length > 15 ? record.agreement_name.slice(0,11)+"..." : record.agreement_name.slice(0,14)}
            </Popover>
        </span>),
    }
     :
     {
      title: t('distributor_agreement_code'),
      dataIndex: 'distributor_agreement_code',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  (
        <span>
            <Popover content={record.distributor_agreement_code}>
              {record.distributor_agreement_code.slice(0,14)}
            </Popover>
        </span>),
    },
    logic.isTenant ? 
    {
      title: t('clienttenant_name'),
      dataIndex: 'clienttenant_name',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      ...getColumnSearchProps('clienttenant_name'),
      render: (text, record) =>  (
        <span>
            <Popover content={`${record.clienttenant_name}`}>
              {record.clienttenant_name.length > 15 ? record.clienttenant_name.slice(0,11)+"..." : record.clienttenant_name.slice(0,14)}
            </Popover>
        </span>),
    }
    :
    {
      title: t('clienttenant_name'),
      dataIndex: 'clienttenant_name',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  (
        <span>
            <Popover content={`${record.clienttenant_name}`}>
              {record.clienttenant_name.length > 15 ? record.clienttenant_name.slice(0,11)+"..." : record.clienttenant_name.slice(0,14)}
            </Popover>
        </span>),
    },
    logic.isTenant ? 
    {
      title: t('objective_name'),
      dataIndex: 'objective_name',
      editable: false,
      width: 160,
      align: 'left',
      fixed: 'left',
      ...getColumnSearchProps('objective_name'),
      render: (text, record) => (
        <span>
          <a>
            <Popover placement="rightTop" content={popOver(record.objData)} arrowPointAtCenter title={t('Objective info')}>
             
                <Icon>
                  <FontAwesomeIcon icon={faEye} />
                </Icon>
               
            </Popover>
          </a>
          <Popover content={`${record.objective_name}`}>
            {record.objective_name.length > 15 ? record.objective_name.slice(0,11)+"..." : record.objective_name.slice(0,14)}
          </Popover>
        </span>
      ),
    } 
    :
    {
      title: t('objective_name'),
      dataIndex: 'objective_name',
      editable: false,
      width: 160,
      align: 'left',
      fixed: 'left',
      render: (text, record) => (
        <span>
          <a>
            <Popover placement="rightTop" content={popOver(record.objData)} arrowPointAtCenter title={t('Objective info')}>
             
                <Icon>
                  <FontAwesomeIcon icon={faEye} />
                </Icon>
               
            </Popover>
          </a>
          <Popover content={`${record.objective_name}`}>
            {record.objective_name.length > 15 ? record.objective_name.slice(0,11)+"..." : record.objective_name.slice(0,14)}
          </Popover>
        </span>
      ),
    },
    {
      title: t('quarter1'),
      dataIndex: 'totalQ1',
      editable: false,
      align: 'right',
    },
    {
      title: t('quarter2'),
      dataIndex: 'totalQ2',
      editable: false,
      align: 'right',
    },
    {
      title: t('quarter3'),
      dataIndex: 'totalQ3',
      editable: false,
      align: 'right',
    },
    {
      title: t('quarter4'),
      dataIndex: 'totalQ4',
      editable: false,
      align: 'right',
    },
    {
      title: t('total'),
      dataIndex: 'total',
      editable: false,
      align: 'right'
    },
    {
      title: t('total_obj'),
      dataIndex: 'total_obj',
      editable: false,
      align: 'right'
    },
    {
      title: t('value_type'),
      dataIndex: 'value_type',
      editable: false,
      align: 'rigth',
      //fixed: 'rigth',
      width: 100
    },
  ])
  const [columns, setColumns] = useState([
    logic.isTenant ? 
    {
      title: t('distributor'),
      dataIndex: 'distributor_name',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  (
        <span>
            <Popover content={record.distributor_name}>
              {record.distributor_name && record.distributor_name.length > 15 ? record.distributor_name.slice(0,11)+'...' : record.distributor_name.slice(0,14)}
            </Popover>
        </span>),
    }
    : 
    {
      title: t('agreement_name'),
      dataIndex: 'agreement_name',
      editable: false,
      align: 'left',
      fixed: 'left',
      width: 150,
      render: (text, record) =>  (
        <span>
            <Popover content={record.agreement_name}>
              {record.agreement_name.length > 15 ? record.agreement_name.slice(0,11)+"..." : record.agreement_name.slice(0,14)}
            </Popover>
        </span>),
    },
    logic.isTenant ? 
    {
      title: t('tenant_agreement_code'),
      dataIndex: 'tenant_agreement_code',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  record.objective_description !== ' ' ?
      (
        <span>
            <Popover content={record.objective_code}>
              {record.objective_code.length > 15 ? record.objective_code.slice(0,11) + " " + "..." : record.objective_code.slice(0,14) + " "}
            </Popover>
            <a>
              <Popover placement="rightTop" content={`${record.objective_description}`}>
                
                  <Icon>
                    <FontAwesomeIcon icon={faStickyNote} />
                  </Icon>
                  
              </Popover>
          </a>
        </span>
      ) :
      (
        <span>
          <Popover content={record.objective_code}>
            {record.objective_code.length > 15 ? record.objective_code.slice(0,11)+"..." : record.objective_code.slice(0,14)}
          </Popover>
        </span>
      ),
    }
    :
    {
      title: t('tenant_agreement_code'),
      dataIndex: 'tenant_agreement_code',
      editable: false,
      width: 150,
      fixed: 'left',
      align: 'left',
      render: (text, record) =>
      (
        <span>
            <Popover content={record.objective_code}>
              {record.objective_code.length > 15 ? record.objective_code.slice(0,11)+"..." : record.objective_code.slice(0,14)}
            </Popover>
        </span>
      ),
    },
    logic.isTenant ? 
    {
      title: t('agreement_name'),
      dataIndex: 'agreement_name',
      editable: false,
      align: 'left',
      fixed: 'left',
      width: 150,
      render: (text, record) =>  (
        <span>
            <Popover content={record.agreement_name}>
              {record.agreement_name.length > 15 ? record.agreement_name.slice(0,11)+"..." : record.agreement_name.slice(0,14)}
            </Popover>
        </span>),
    }
     :
    {
      title: t('distributor_agreement_code'),
      dataIndex: 'distributor_agreement_code',
      editable: true,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  (
        <span>
            <Popover content={record.distributor_agreement_code}>
              {record.distributor_agreement_code.slice(0,14)}
            </Popover>
        </span>),
    },
    logic.isTenant ? 
    {
      title: t('clienttenant_name'),
      dataIndex: 'clienttenant_name',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      ...getColumnSearchProps('clienttenant_name'),
      render: (text, record) =>  (
        <span>
            <Popover content={`${record.clienttenant_name}`}>
              {record.clienttenant_name.length > 15 ? record.clienttenant_name.slice(0,11)+"..." : record.clienttenant_name.slice(0,14)}
            </Popover>
        </span>),
    } 
    :
    {
      title: t('clienttenant_name'),
      dataIndex: 'clienttenant_name',
      editable: false,
      width: 150,
      align: 'left',
      fixed: 'left',
      render: (text, record) =>  (
        <span>
            <Popover content={`${record.clienttenant_name}`}>
              {record.clienttenant_name.length > 15 ? record.clienttenant_name.slice(0,11)+"..." : record.clienttenant_name.slice(0,14)}
            </Popover>
        </span>),
    }, 
    logic.isTenant ? 
    {
      title: t('objective_name'),
      dataIndex: 'objective_name',
      editable: false,
      width: 160,
      align: 'left',
      fixed: 'left',
      ...getColumnSearchProps('objective_name'),
      render: (text, record) => (
        <span>
          <a>
            <Popover placement="rightTop" content={popOver(record.objData)} arrowPointAtCenter title={t('Objective info')}>
             
                <Icon>
                  <FontAwesomeIcon icon={faEye} />
                </Icon>
               
            </Popover>
          </a>
          <Popover content={`${record.objective_name}`}>
            {record.objective_name.length > 15 ? record.objective_name.slice(0,11)+"..." : record.objective_name.slice(0,14)}
          </Popover>
        </span>
      ),
    }
    :
    {
      title: t('objective_name'),
      dataIndex: 'objective_name',
      editable: false,
      width: 160,
      align: 'left',
      fixed: 'left',
      render: (text, record) => (
        <span>
          <a>
            <Popover placement="rightTop" content={popOver(record.objData)} arrowPointAtCenter title={t('Objective info')}>
             
                <Icon>
                  <FontAwesomeIcon icon={faEye} />
                </Icon>
               
            </Popover>
          </a>
            <Popover content={`${record.objective_name}`}>
              {record.objective_name.length > 15 ? record.objective_name.slice(0,11)+"..." : record.objective_name.slice(0,14)}
            </Popover>
        </span>
      ),
    },
    {
      title: t('january'),
      dataIndex: 'january',
      editable: new Date().getMonth() === 1 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 1 ? text => <a>{text}</a> : null,
    },
    {
      title: t('february'),
      dataIndex: 'february',
      editable: new Date().getMonth() === 2 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 2 ? text => <a>{text}</a> : null,
    },
    {
      title: t('march'),
      dataIndex: 'march',
      editable: new Date().getMonth() === 3 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 3 ? text => <a>{text}</a> : null,
    },
    {
      title: t('april'),
      dataIndex: 'april',
      editable: new Date().getMonth() === 4 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 4 ? text => <a>{text}</a> : null,
    },
    {
      title: t('may'),
      dataIndex: 'may',
      editable: new Date().getMonth() === 5 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 5 ? text => <a>{text}</a> : null,
    },
    {
      title: t('june'),
      dataIndex: 'june',
      editable: new Date().getMonth() === 6 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 6 ? text => <a>{text}</a> : null,
    },
    {
      title: t('july'),
      dataIndex: 'july',
      editable: new Date().getMonth() === 7 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 7 ? text => <a>{text}</a> : null,
    },
    {
      title: t('august'),
      dataIndex: 'august',
      editable: new Date().getMonth() === 8 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 8 ? text => <a>{text}</a> : null,
    },
    {
      title: t('september'),
      dataIndex: 'september',
      editable: new Date().getMonth() === 9 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 9 ? text => <a>{text}</a> : null,
    },
    {
      title: t('october'),
      dataIndex: 'october',
      editable: new Date().getMonth() === 10 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 10 ? text => <a>{text}</a> : null,
    },
    {
      title: t('november'),
      dataIndex: 'november',
      editable: new Date().getMonth() === 11 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 11 ? text => <a>{text}</a> : null,
    },
    {
      title: t('december'),
      dataIndex: 'december',
      editable: new Date().getMonth() === 0 ? true : false,
      align: 'right',
      width: 100,
      render: new Date().getMonth() === 0 ? text => <a>{text}</a> : null,
    },
    {
      title: t('value_type'),
      dataIndex: 'value_type',
      editable: false,
      align: 'rigth',
      fixed: 'right',
      width: 100,
    },

  ])
  const [whichTable, setWhichTable] = useState('details')
  
  const prepareEditableCols = (columns, selectedYear, newRowsWithoutTotals) => {
    let isSalesYear = false
    if (new Date().getFullYear() === parseInt(selectedYear) + 1 && new Date().getMonth() === 0 ||
        new Date().getFullYear() === parseInt(selectedYear) && new Date().getMonth() - 1 > -1) {
         new Date().getDate() < process.env.REACT_APP_MAX_UNPROTECTED_DAY ? isSalesYear = true : isSalesYear = false // Sales can be modified till 10th of the month included
         // <<<<---------------------------------------------------------------------------------------------->>>
         // Hardcoded for PRO - We need for demos to have always unprotected the demo user
         if (process.env.REACT_APP_USERID_AGR_ALWAYS_UNPROTECTED.includes(logic.distributorId)) isSalesYear = true
         // <<<<---------------------------------------------------------------------------------------------->>>
    }
    let unProtectedMonth = -1
    if (new Date().getFullYear() === parseInt(selectedYear) && new Date().getMonth() -1 > -1) {
      unProtectedMonth = new Date().getMonth()
    }
    if (new Date().getFullYear() === parseInt(selectedYear) +1 && new Date().getMonth() === 0) {
      unProtectedMonth = 12
    }
    const newColumns = columns.map(col => {
      const updatedCol = {...col}
      if (col.dataIndex === 'objective_name') {
        if  (isSalesYear) {
          updatedCol.render = (text, record) => new Date(record.objData.starttime).getMonth()+1 === unProtectedMonth && new Date(record.objData.starttime).getFullYear() === parseInt(selectedYear) 
                                        ? (
                                          <span>   
                                              <a>
                                                <Popover placement="rightTop" content={popOver(record.objData)} arrowPointAtCenter title={t('Objective info')}>
                                                
                                                    <Icon>
                                                      <FontAwesomeIcon icon={faEye} />
                                                    </Icon>
                                                  
                                                </Popover>
                                              </a>
                                                <Popover content={`${record.objective_name}`}>
                                                  {record.objective_name.length > 15 ? record.objective_name.slice(0,11)+"..."+" " : record.objective_name.slice(0,14) + " "}
                                                </Popover>
                                                <Popover placement="rightTop" content={t('1st month report')}>
                                                  <Icon>
                                                        <FontAwesomeIcon icon={faFlagCheckered} />
                                                  </Icon>
                                                </Popover>
                                          </span>
                                        )
                                        : (
                                          <span>
                                            <a>
                                              <Popover placement="rightTop" content={popOver(record.objData)} arrowPointAtCenter title={t('Objective info')}>
                                               
                                                  <Icon>
                                                    <FontAwesomeIcon icon={faEye} />
                                                  </Icon>
                                                 
                                              </Popover>
                                            </a>
                                              <Popover content={`${record.objective_name}`}>
                                                {record.objective_name.length > 15 ? record.objective_name.slice(0,11)+"..."+" " : record.objective_name.slice(0,14) + " "}
                                              </Popover>
                                          </span>
                                        )
        }
        return {
          ...updatedCol,
          //...col,
          onCell: (record) => ({
            record,
            editable: false,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: handleSave,
          })
        }
      }
      if(col.dataIndex === 'distributor_agreement_code' && !logic.isTenant && !logic.isAdmin) {
        updatedCol.editable = true
        updatedCol.render = MONTHS[unProtectedMonth] ? text => <a>{text}</a> : null
        return {
          ...updatedCol,
          onCell: (record) => ({
              record,
              editable: MONTHS[unProtectedMonth] ? true : false,
              dataIndex: col.dataIndex,
              title: col.title,
              handleSave: handleSave,
            })
          }
      }
      if (logic.isTenant || logic.isAdmin) {
        updatedCol.editable = false
        if (Object.values(MONTHS).includes(col.dataIndex)) updatedCol.render = null
        delete updatedCol.onCell
        return updatedCol
      }
      if (!col.editable && col.dataIndex !== MONTHS[unProtectedMonth]) {
        return col
      }
      if (!isSalesYear) {
        updatedCol.editable = false
        updatedCol.render = null
        delete updatedCol.onCell
        return updatedCol
      }
      updatedCol.editable = true
      updatedCol.render = text => <a>{text}</a>
      return {
        ...updatedCol,
        onCell: (record) => ({
            record,
            editable: col.dataIndex === MONTHS[unProtectedMonth] ? true : false,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: handleSave,
          })
        }
    })
    return newColumns
  }

  const prepareTable = (theYear, selectedYearDistTenantObj) => {
    let newRows = []
    if (!selectedYearDistTenantObj.length) {
      return newRows
    } 
    const reportedYYYYMM = `${(new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear())}${(new Date().getMonth() === 0 ? 12 : new Date().getMonth()).toString().padStart(2, '0')}`   
    let id1stHalf = selectedYearDistTenantObj.length && selectedYearDistTenantObj[0].distributorid
    let id2ndHalf = selectedYearDistTenantObj.length && selectedYearDistTenantObj[0].objectiveid
    let id = id1stHalf.toString()+id2ndHalf.toString()
    let objMonth
    let totalSales = 0
    let qTotal = new Array(0,0,0,0)
    let newRow = {
      key: parseInt(id),
      objective_code: selectedYearDistTenantObj.length && " " + selectedYearDistTenantObj[0].objectivecode,      
      objective_name: selectedYearDistTenantObj.length && " " + selectedYearDistTenantObj[0].objectivename,
      objective_description: selectedYearDistTenantObj.length && " " + selectedYearDistTenantObj[0].objectivedescription,
      agreement_name: selectedYearDistTenantObj.length && selectedYearDistTenantObj[0].agreementtypename,
      clienttenant_description: selectedYearDistTenantObj.length && selectedYearDistTenantObj[0].clientdescription ? " " + selectedYearDistTenantObj[0].clientdescription : " ",
      clienttenant_name: selectedYearDistTenantObj.length && " " + selectedYearDistTenantObj[0].clientname,
      clients_CIFs: selectedYearDistTenantObj.length && " " + selectedYearDistTenantObj[0].client.join(''),
      distributor_agreement_code: selectedYearDistTenantObj.length && selectedYearDistTenantObj[0].distributorobjcode !== null ? selectedYearDistTenantObj[0].distributorobjcode : '',
      value_type: selectedYearDistTenantObj.length && t(selectedYearDistTenantObj[0].valuetype),
      totalQ1: '0,00',
      totalQ2: '0,00',
      totalQ3: '0,00',
      totalQ4: '0,00',
      total: '0,00',
      total_obj: selectedYearDistTenantObj.length &&  selectedYearDistTenantObj[0].totalvalueobjective.toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      areThereErrors: false,
      isTotal: false,
      isCurrentMonthProtected: checkMonthValueProtection(reportedYYYYMM, selectedYearDistTenantObj[0].starttime, selectedYearDistTenantObj[0].endtime),
      objData: selectedYearDistTenantObj[0],
      registeredValues: []
    }
    if (logic.isTenant) {
      newRow.distributor_name = selectedYearDistTenantObj[0].distributorname
    } else {
      newRow.dummy = ''
    }
    initializeMonths(newRow, prepareArray(theYear, selectedYearDistTenantObj[0]))

    for (let index = 0; index < selectedYearDistTenantObj.length; index++) {
      if (parseInt(id) !== parseInt(selectedYearDistTenantObj[index].distributorid.toString() + 
                                    selectedYearDistTenantObj[index].objectiveid.toString()) && 
           index !== 0) {
        newRow.totalQ1 = qTotal[0].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        newRow.totalQ2 = qTotal[1].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        newRow.totalQ3 = qTotal[2].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        newRow.totalQ4 = qTotal[3].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        qTotal = new Array(0,0,0,0)
        newRow.total = totalSales.toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        totalSales = 0
        newRows.push({...newRow})
        id1stHalf = selectedYearDistTenantObj[index].distributorid
        id2ndHalf = selectedYearDistTenantObj[index].objectiveid
        id = id1stHalf.toString()+id2ndHalf.toString()
        newRow.key = parseInt(id)
        newRow.objective_code = " " + selectedYearDistTenantObj[index].objectivecode
        newRow.objective_name = " " + selectedYearDistTenantObj[index].objectivename
        newRow.objective_description = " " + selectedYearDistTenantObj[index].objectivedescription
        newRow.agreement_name = selectedYearDistTenantObj[index].agreementtypename  
        newRow.clienttenant_description = " " + selectedYearDistTenantObj[index].clientdescription ? " " + selectedYearDistTenantObj[index].clientdescription : " "
        newRow.clienttenant_name = " " + selectedYearDistTenantObj[index].clientname
        newRow.clients_CIFs = " " + selectedYearDistTenantObj[index].client.join('')
        newRow.distributor_agreement_code = selectedYearDistTenantObj[index].distributorobjcode !== null ? selectedYearDistTenantObj[index].distributorobjcode : ''
        newRow.value_type = t(selectedYearDistTenantObj[index].valuetype)
        newRow.dummy = ""
        newRow.areThereErrors = false
        newRow.total_obj = selectedYearDistTenantObj[index].totalvalueobjective.toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        newRow.total = '0,00'
        newRow.isTotal = false
        newRow.isCurrentMonthProtected = checkMonthValueProtection(reportedYYYYMM, selectedYearDistTenantObj[index].starttime, selectedYearDistTenantObj[index].endtime)
        newRow.objData = selectedYearDistTenantObj[index]
        newRow.registeredValues = []
        if (logic.isTenant) {
          newRow.distributor_name = selectedYearDistTenantObj[index].distributorname
        } else {
          newRow.dummy = ''
        }
        initializeMonths(newRow, prepareArray(theYear, selectedYearDistTenantObj[index]))
      }
      if(selectedYearDistTenantObj[index].registrytime !== null && 
          selectedYearDistTenantObj[index].realvalue !== null &&
          new Date(selectedYearDistTenantObj[index].registrytime).getFullYear() === parseInt(theYear)) {
            objMonth = new Date(selectedYearDistTenantObj[index].registrytime).getMonth()+1
            newRow[MONTHS[objMonth]] = selectedYearDistTenantObj[index].realvalue.toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            totalSales = totalSales + parseFloat(selectedYearDistTenantObj[index].realvalue)
            switch (objMonth) {
              case 1: case 2: case 3:
                qTotal[0] = qTotal[0] + parseFloat(selectedYearDistTenantObj[index].realvalue)
                break
              case 4: case 5: case 6:
                qTotal[1] = qTotal[1] + parseFloat(selectedYearDistTenantObj[index].realvalue)
                break
              case 7: case 8: case 9:
                qTotal[2] = qTotal[2] + parseFloat(selectedYearDistTenantObj[index].realvalue)
                break
              case 10: case 11: case 12:
                qTotal[3] = qTotal[3] + parseFloat(selectedYearDistTenantObj[index].realvalue)
                break
            }
      } 
      if(selectedYearDistTenantObj[index].registrytime !== null && 
        selectedYearDistTenantObj[index].realvalue !== null) {
          newRow.registeredValues.push({objectivedistributorid: selectedYearDistTenantObj[index].objectivedistributorid, registrytime: selectedYearDistTenantObj[index].registrytime, realvalue: selectedYearDistTenantObj[index].realvalue, distributorobjcode: selectedYearDistTenantObj[index].distributorobjcode})
      }
    }
    newRow.total = totalSales.toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    newRow.totalQ1 = qTotal[0].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    newRow.totalQ2 = qTotal[1].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    newRow.totalQ3 = qTotal[2].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    newRow.totalQ4 = qTotal[3].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    newRows.push({...newRow})
    return newRows
  }

  const checkMonthValueProtection = (unprotectedMonth, objStartTime, objEndTime) => {
    let toCompareObjStartTime = `${new Date(objStartTime).getFullYear()}${(new Date(objStartTime).getMonth() + 1).toString().padStart(2, '0')}` 
    let toCompareObjEndTime = `${new Date(objEndTime).getFullYear()}${(new Date(objEndTime).getMonth() +1).toString().padStart(2, '0')}` 
    if (toCompareObjStartTime <= unprotectedMonth && toCompareObjEndTime >=  unprotectedMonth) {
        return false
      } else {
        return true
      }
  }

  const prepareArray = (theYear, objectiveData) => {
    let initialMonth = 0
    let finalMonth = 0
    let initialArray = []

    if (!objectiveData) return [] // Guard clause when no objectives
    if (new Date(objectiveData.starttime).getFullYear() <= parseInt(theYear) &&
         new Date(objectiveData.endtime).getFullYear() >= parseInt(theYear)) 
    {
      new Date(objectiveData.starttime).getFullYear() < parseInt(theYear) ? initialMonth = 1 : initialMonth = new Date(objectiveData.starttime).getMonth() + 1
      new Date(objectiveData.endtime).getFullYear() > parseInt(theYear) ? finalMonth = 12 : finalMonth = new Date(objectiveData.endtime).getMonth() + 1
    } 
    for (let i = 1; i < 13; i++) {
      if (i >= initialMonth && i <= finalMonth) {
        initialArray.push(0) 
      } else {
        initialArray.push('')
      }
    }
    return initialArray
  }

  const initializeMonths = (theRow, theValues) => {
    for (let i = 1; i < 13; i++) { // Adding months initialized to the total sales in the year
      if (theValues[i-1] === '') {
        theRow[MONTHS[i]] = '-'
      }else {
        theRow[MONTHS[i]] = theValues[i-1].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      } 
    }
    return theRow
  }

  const handleYearPick = (value) => {
    const { tenantId, year, types, tenantName, distributors } = value
    setShownYear(year)
    setSelectedTenant(tenantId)
    setSelectedTenantName(tenantName)
    setSelectedDistributors(distributors && distributors.length ? [...distributors] : null)
    setSelectedTypes(types && types.length ? [...types] : null)
    if (tenantId && year) {
      updateTable(year, tenantId, null, types)
      alert.show(t("Sales successfully retrieved"), { type: 'success' })
      setHasRegistries(false)
      setIsHidden(false)
    } else if (distributors && distributors.length && year) {
      updateTable(year, null, distributors, types)
      alert.show(t("Distributors' sales successfully retrieved"), { type: 'success' })
      setIsHidden(false)
    } 
  }

  const downloadData = async () => {
    try {
      const theExcelCsv = await downloadAllDistributorsTenantSales(dataSource)
      const data = theExcelCsv && theExcelCsv.data
      let a = document.createElement('a')
      a.href = `${logic.url}/${data.path}`
      a.download = data.name
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      await logic.insertAuditTrail({ module: '/auditTrail/add', message: AUDITTRAILREPORTMSG('agreements sales of on screen selected distributors', `${shownYear} to ${shownYear}`, logic.tenantId.toString()) })
    } catch (error) {
      console.log("Error while downloading all the distributors' tenant sales report")
      throw Error(error)
    }
  }

  function updateTable(theYear, theTenant, theDistributors, theTypes) {
    let selectedObjectives = []
    if (logic.isTenant) {
      if (theDistributors[0].distributorname === t('Todos los distribuidores')) {
        selectedObjectives = allDistributorTenantObjectives.filter(distributorObj => {
          if (theYear >= new Date(distributorObj.starttime).getFullYear() && 
               theYear <= new Date(distributorObj.endtime).getFullYear()) {
                return distributorObj
          }
        })
      } else {
        selectedObjectives = allDistributorTenantObjectives.filter(distributorObj => {
          if (theYear >= new Date(distributorObj.starttime).getFullYear() && 
               theYear <= new Date(distributorObj.endtime).getFullYear() &&
               theDistributors.find(distributor => parseInt(distributor.distributortenantid) === parseInt(distributorObj.distributorid))) {
                return distributorObj
          }
        })
      }
    } else {
      selectedObjectives = allDistributorObjectives.filter(distributorObj => {
        if (theYear >= new Date(distributorObj.starttime).getFullYear() && 
             theYear <= new Date(distributorObj.endtime).getFullYear() &&
             parseInt(theTenant) === distributorObj.tenantid) {
              return distributorObj
        }
      })
    }
    if (theTypes && theTypes[0] && theTypes[0].agreementtypename !== t('Todos los negocios')) {
      selectedObjectives = selectedObjectives.filter(distributorObj => {
        return theTypes.find(type => parseInt(type.agreementtypeid) === parseInt(distributorObj.agreementtypeid))
      })
    }
    newRows = prepareTable(theYear, selectedObjectives)
    let newRowsWithoutTotals = [...newRows]
    setDataSource(prevState => [...newRowsWithoutTotals])
    setTotalTotals(prevState => [...newRowsWithoutTotals])
    const newColumns = prepareEditableCols(columns, theYear, newRowsWithoutTotals)
    setColumns([...newColumns])
    setTableLoading(false)
  }

  function callback (key) {
    if (parseInt(key) === 2) {
      setWhichTable('totals')
    } else {
      setWhichTable('details')
    }
  }

  // components for the editable rows
  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell
    }
  }

  const handleSave = (row) => {
    const newData = [...newRows]
    const index = newData.findIndex((item) => row.key === item.key)
    const prevRow = newData[index] // objective row before modifying it 
    if(row.areThereErrors) {
      row.areThereErrors = false
      if (index !== -1) {  // if findIndex fails, don't splice
        newData.splice(index, 1, {
          ...prevRow,
          ...row
        })
        return
      }
    }
    const currentMonth = new Date().getMonth() -1 > -1 ? new Date().getMonth() : 12
    if (parseFloat(row.objData.realvalue) !== parseFloat(row[MONTHS[currentMonth]].split('.').join('').replace(',','.'))) {
      setHasRegistries(true)
    }
    const prevTotal = newData[newData.length - 1] // total row before modifiying it
   
    // When an import is modified we have to update three totals:
    // a) The total of the objective where the new import has been entered
    let newRowTotal = 0
    let newRowQs  = new Array(0,0,0,0)
    for (let i=1; i<13; i++) {
      if (row[MONTHS[i]] !== '-') {
        newRowTotal = newRowTotal + parseFloat(row[MONTHS[i]].split('.').join('').replace(',','.'))
        switch (i) {
          case 1: case 2: case 3:
            newRowQs[0] = newRowQs[0] + parseFloat(row[MONTHS[i]].split('.').join('').replace(',','.'))
            break
          case 4: case 5: case 6:
            newRowQs[1] = newRowQs[1] + parseFloat(row[MONTHS[i]].split('.').join('').replace(',','.'))
            break
          case 7: case 8: case 9:
            newRowQs[2] = newRowQs[2] + parseFloat(row[MONTHS[i]].split('.').join('').replace(',','.'))
            break
          case 10: case 11: case 12:
            newRowQs[3] = newRowQs[3] + parseFloat(row[MONTHS[i]].split('.').join('').replace(',','.'))
            break
        }
      } 
    }
    row.total = newRowTotal.toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    row.totalQ1 = newRowQs[0].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    row.totalQ2 = newRowQs[1].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    row.totalQ3 = newRowQs[2].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    row.totalQ4 = newRowQs[3].toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")

   
    // b) The total of the objective's month
    let newMonthTotal = 0
    for (let i=0; i<newRows.length - 1; i++) {
      if (row.key !== newRows[i].key) {
        if (newRows[i][MONTHS[currentMonth]] !== '-') {
          newMonthTotal = newMonthTotal + parseFloat(newRows[i][MONTHS[currentMonth]].split('.').join('').replace(',','.'))  
        }
      } else {
        newMonthTotal = newMonthTotal + parseFloat(row[MONTHS[currentMonth]].split('.').join('').replace(',','.'))
      }
    }
    if (index !== -1) {  // if findIndex fails, don't splice
      newData.splice(index, 1, {
        ...prevRow,
        ...row
      })
    }
    let newDataWithoutTotals = [...newData]
    setDataSource(prevState => [...newDataWithoutTotals])
    setTotalTotals(prevState => [...newDataWithoutTotals])
    newRows = [...newData]
  }

  const updateSales = async () => {
    try {
      setIsSavingData(true)
      const currentMonth = new Date().getMonth() -1 > -1 ? new Date().getMonth() : 12
      let haveToReload = false
      let objDistData = {
      }
      let registeredValue, registeredCode = {}
      for (let i = 0; i < dataSource.length; i++) {
        registeredValue = dataSource[i].registeredValues.filter(registeredValue => {
          if (new Date(registeredValue.registrytime).getFullYear() === parseInt(shownYear) && 
               new Date(registeredValue.registrytime).getMonth() + 1 === currentMonth) {
                 return registeredValue
               }
        })
        registeredCode = dataSource[i].registeredValues.filter(registeredCode => {
          if(registeredCode.distributorobjcode !== dataSource[i].distributor_agreement_code) {
            return registeredCode
          }
        })
        if (registeredCode.length) {
          console.log("pillo: ", dataSource[i].distributor_agreement_code)
          await updateObjDistributor({ objectiveId: dataSource[i].objData.objectiveid.toString(), 
                                                          distributorId: logic.distributorId.toString(), 
                                                          distributorObjCode: dataSource[i].distributor_agreement_code })
          haveToReload = true
        }
        if (!registeredValue.length &&
            dataSource[i][MONTHS[currentMonth]] !== '-') {
            objDistData = {}
            objDistData.objectiveId = dataSource[i].objData.objectiveid
            objDistData.distributorId = logic.distributorId.toString()
            objDistData.realValue = parseFloat(dataSource[i][MONTHS[currentMonth]].split('.').join('').replace(',','.'))
            objDistData.registryTime = `${new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear()}-${(new Date().getMonth() === 0 ? 12 : new Date().getMonth()).toString().padStart(2, '0')}-01`
            console.log("objDistData insert: ", objDistData)
            await insertDistTenantObj(objDistData)
            haveToReload = true
            continue
        }
        if (registeredValue.length &&
             parseFloat(registeredValue[0].realvalue) !== parseFloat(dataSource[i][MONTHS[currentMonth]].split('.').join('').replace(',','.'))) {
              objDistData = {}
              objDistData.objectiveDistributorId = registeredValue[0].objectivedistributorid
              objDistData.objectiveRealValue = parseFloat(dataSource[i][MONTHS[currentMonth]].split('.').join('').replace(',','.'))
              objDistData.objectiveOldToReplaceValue = parseFloat(registeredValue[0].realvalue)
              await updateDistTenantObj(objDistData)
              haveToReload = true
              continue
        }
      }
      if (haveToReload) {
        alert.show(t('objectiveDistTenantUpdated'), { type: 'success' })
        window.location.reload(true)
      }
    } catch (error) {
      console.log("Error in updating distributor's sales")
      throw Error(error)
    }
  }

  return (
    <Box id='objectivesSales'>
      <TabsSales highlight='sales' />
        <YearPicker hasRegistries={hasRegistries} doYearPick={handleYearPick} downloadData={downloadData}/>
        <div style={isHidden ? { display: 'none' } : { display: 'block' }}>
          <br/>
          {!logic.isTenant ? 
            <Level.Item align='left'>
                <Button
                        onClick={updateSales}
                        type='primary'
                        style={{ marginBottom: 16, marginLeft: 10 }}
                        loading={isSavingData}
                >
                        <Icon type='save' />{' '}
                    {t('Save Data')}
                </Button>
            </Level.Item>
            : null
          }
          <div style={{ marginTop: 20 }}>
            <Tabs onChange={callback} type='card'>
              <TabPane tab={t('objective_plural')} key='1'>
                    <Table style={{maxWidth: 1600}}
                      components={components}
                      rowClassName={() => 'editable-row'}
                      dataSource={dataSource}
                      columns={columns}
                      loading={tableLoading}
                      scroll={{ x: 'auto' }}
                    />
              </TabPane>
              <TabPane tab={t('objective_totals')} key='2'>
                    <Table style={{maxWidth: 1600}}
                      dataSource={totalTotals}
                      columns={columnsTotalTotals}
                      loading={tableLoading}
                      scroll={{ x: 'auto' }}
                    />
              </TabPane>
            </Tabs>
          </div>
          {!logic.isTenant ? 
            <Level.Item align='left'>
                <Button
                        onClick={updateSales}
                        type='primary'
                        style={{ marginBottom: 16, marginLeft: 10 }}
                        loading={isSavingData}
                >
                        <Icon type='save' />{' '}
                    {t('Save Data')}
                </Button>
            </Level.Item>
            : null
          }
        </div>
    </Box>
  )
}