/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { Select, Box } from 'rbx'
import { TabsClients } from '../TabsClients'
import './NewClients.scss'
import { Switch } from 'antd'
import { Bar } from 'react-chartjs-2'
import { Column } from 'rbx'
import * as dayjs from 'dayjs'
import { DashboardCard } from '../../dashboard/card'

export const NewClients = () => {
  // i18n hook
  const { t } = useTranslation()

  // main state
  const [selectedYear, setSelectedYear] = useState(new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear())

  // main state
  const [newClientsMonth, setNewClientsMonth] = useState(new Array(12).fill('0'))

  // main state
  const [newClientsEvolution, setNewClientsEvolution] = useState(new Array(12).fill('0'))
  // main state
  const [totalClients, setTotalClients] = useState(0)

  // main state
  const [annualClients, setAnnualClients] = useState(0)
  // main state
  const [averageClients, setAverageClients] = useState(new Array(12).fill('0'))
  
  const data = {
    labels: [t('Enero'), t('Febrero'), t('Marzo'), t('Abril'), t('Mayo'), t('Junio'), t('Julio'), t('Agosto'), t('Setpiembre'), t('Octubre'), t('Noviembre'), t('Diciembre')],
    datasets: [
      {
        type: 'bar',
        label: '# Nuevos clientes',
        data: newClientsMonth,
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        type: 'bar',
        label: '# Clientes',
        data: newClientsEvolution,
        backgroundColor: 'rgb(54, 162, 235)',
      },
      { 
        type:'line',
        label: 'Media Año',
        data: averageClients,
        fill: false
      }
    ],
  }
  
  const currentYear = new Date().getFullYear()
  const fromYear = 2020
  const toYear = new Date(currentYear, 11).getFullYear()
  const years = []
  for (let i = toYear; i >= fromYear; i -= 1) {
    years.push(i)
  }

  // Fetch products when component mounts
  useEffect(() => {
    logic
    .getMonthlyNewClients(selectedYear)
    .then(distributorsStatus => {
      let array = new Array(12).fill('0')
      const newClients = distributorsStatus && distributorsStatus.data
      for(let i = 0; i < newClients.length; i++) {
        let fullDay = dayjs(newClients[i].date)
        let month = fullDay.month()
        array[month] = newClients[i].newclients
      }
      setNewClientsMonth(array)
    })

    logic
    .getMonthlyNewClientsEvolution(selectedYear)
    .then(distributorsStatus => {
      let array = new Array(12).fill('0')
      const newClients = distributorsStatus && distributorsStatus.data
      for(let i = 0; i < newClients.length; i++) {
        let fullDay = dayjs(newClients[i].Fecha)
        let month = fullDay.month()
        array[month] = newClients[i].Clientes
      }
      setNewClientsEvolution(array)
    })

    logic.getTotalClients()
      .then(response => {
        const totalClients =
        response &&
        response.data &&
        response.data[0] &&
        response.data[0].count
        setTotalClients(totalClients || 0)
      })

      logic.getTotalClientsByYear(selectedYear)
      .then(response => {
        console.log('percent', response)
        const totalClients =
        response &&
        response.data &&
        response.data[0] &&
        response.data[0].count
        setAnnualClients(totalClients || 0)
      })


      logic.getAverageClients(selectedYear)
      .then(response => {
        console.log('percent', response)
        const averageClients =
        response &&
        response.data &&
        response.data[0] &&
        response.data[0].avg && new Array(12).fill(response.data[0].avg)

        setAverageClients(averageClients)
      })
    
  }, [selectedYear])

  // components for the editable rows

  const handleSelectYear = event => {
    const year = event.target.value
    setSelectedYear(year)
    
  }

  return (
    <>
      <Box>

        <TabsClients highlight='new' />
        {logic.notCrossSales && <Switch checkedChildren={t('Sin vtas cruzadas')} disabled checked />} 
        {!logic.notCrossSales && <Switch unCheckedChildren={t('Con vtas cruzadas')} disabled />} 
        <br/>
        <br/>
        <Select.Container>
          <Select value={selectedYear} onChange={handleSelectYear}>
            {years.map((year, index) => {
              return (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              )
            })}
          </Select>
        </Select.Container>
        <Column.Group>
          <Column>
            <Bar size data={data}></Bar>
          </Column>
          <Column size='one-quarter' style={{paddingTop: '56px'}}>
          <Column.Group>
            <DashboardCard title={t('Clientes totales')} content={`${totalClients}`}></DashboardCard>
          </Column.Group>
          <Column.Group>
            <DashboardCard title={t('Clientes del año')} content={annualClients || '-'}></DashboardCard>
          </Column.Group>
          </Column>
        </Column.Group>
      </Box>
    </>
  )
}