/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Select, Textarea, Box, Title, Field, Input, Control, Label, Button, Icon, Checkbox, Level } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../error'
import { logic } from '../../logic'
import { TabsDistributor } from '../distributor/tabsDistributor'
import { useConfirmActionMessage } from '../../logic/hooks'
import { validateSpanishId } from 'spain-id'
import { MAXMONTHTOBLOCK } from '../../config/config'

export const BUTTON_STATES = { hovered: 'hovered', focused: 'focused', active: 'active', loading: 'loading' }

export const AddEditTenantAndDistributor = () => {
  const alert = useAlert()
  const [distributor, setDistributor] = useState()
  const [primaryContacts, setPrimaryContacts] = useState()
  const [isUploadedBlockedClicked, setIsUploadedBlockedClicked] = useState(false)
  // Error handling
  const confirmAction = useConfirmActionMessage()
  // Button request loading
  const [buttonState, setButtonState] = useState(BUTTON_STATES.active)
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedPostalCode, setSelectedPostalCode] = useState({})
  const [allPostalCodes, setAllPostalCodes] = useState([])

  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { tenantId, tenantIdentity, id, postalCodeId } = useParams()
  const { register, handleSubmit, errors, watch, triggerValidation, clearError, setError } = useForm()

  useEffect(() => {
    (async function () {
      try {
        if (tenantId || id) {
          let distributorTenant
          if (tenantId) {
            distributorTenant = await logic.getOneDistributorTenantAdmin({ tenantIdentity: tenantIdentity.toString(), tenantId: tenantId.toString(), postalCodeId: postalCodeId.toString() })
          } 
          if(id) {
            distributorTenant = await logic.getOneDistributorTenant({ distributorTenantId: id.toString() })
            setIsUploadedBlockedClicked(distributorTenant && distributorTenant.data && distributorTenant.data[0].isuploadblocked)
          }
          setDistributor(distributorTenant && distributorTenant.data && distributorTenant.data[0])
          setSelectedCountry(distributorTenant && distributorTenant.data && distributorTenant.data[0] && distributorTenant.data[0].country)
          await validateCountryAndPostalCode(distributorTenant && distributorTenant.data && distributorTenant.data[0] && distributorTenant.data[0].postalcode, distributorTenant && distributorTenant.data && distributorTenant.data[0] && distributorTenant.data[0].country, distributorTenant && distributorTenant.data && distributorTenant.data[0] && distributorTenant.data[0].postalcodeid)
        }
        await getCountries() 
      } catch (error) {
          alert.show(error.message, {
            type: 'error'
          })
      }
    })()
  }, [])

  useEffect(() => {
    async function getPrimaryContacts () {
      if (id) {
        try {
          const users = await logic.getUserDistributors({ distributorId: id })
          const sortedUsers =
                users &&
                users.data &&
                users.data.sort((a, b) => {
                  if (a.username < b.username) {
                    return -1
                  }
                  if (a.username > b.username) {
                    return 1
                  }
                  return 0
                })
          setPrimaryContacts(sortedUsers)
  
        } catch (error) {
          alert.show(t('getuserdistributorserror'), {
            type: 'error'
          })
        }
      }
    }
    getPrimaryContacts()
  }, [])

  const handleGoBackClick = () => {
    url.includes('/tenants') ? history.push('/tenants') : history.push('/distributor')
  }

  async function getCountries() {
    try {
      const sessionId = logic.sessionId.toString()
      const countries = await logic.getCountries(sessionId)
      let data = countries && countries.data 
      data = data.filter(item => {
        return item.active
      }) 
      const sortedCountries =
        data.sort((a, b) => {
          if (a.countrynamesp.toLowerCase() < b.countrynamesp.toLowerCase()) {
            return -1
          }
          if (a.countrynamesp.toLowerCase() > b.countrynamesp.toLowerCase()) {
            return 1
          }
          return 0
        })
      setCountries(sortedCountries)
    } catch (error) {
        alert.show(t('getcountrieserror'), {
          type: 'error'
        })
    }
  }

  const onSubmitData = data => {
    setButtonState(BUTTON_STATES.loading)

    const { distributorCode, distributorIdentity, distributorName, geoarea, country, phone, alternatePhone, address, primaryContactId, salesStock, isUploadBlocked, dateBlocked, description } = data
    const notifySalesStock = salesStock
    confirmAction.closeMessage()
    if (url.includes('/tenants')) {
      if (tenantId) {
        logic
          .updateTenantAdmin({
            tenantId: tenantId.toString(),
            distributorIdentity,
            distributorName,
            distributorCode,
            postalCodeId: geoarea,
            country,
            address,
            phone,
            alternatePhone,
            description
          })
          .then(tenant => {
            if (tenant.status !== 200) {
              alert.show(t(`${tenant.error}`), {
                type: 'error'
              })
            } else {
              alert.show('proveedor actualizado correctamente', {
                type: 'success'
              })
              history.push({
                pathname: '/tenants'
              })
            }
          })
          .catch(err => {
            if (err.message === '409') {
              alert.show(t('Input already exists'), {
                type: 'error'
              })
            } else {
              alert.show(t('Unexpected Error'), {
                type: 'error'
              })
            }
          })
      } else {
        logic
          .addTenantAdmin({
            distributorIdentity,
            distributorName,
            distributorCode,
            postalCodeId: geoarea,
            country,
            address,
            phone,
            alternatePhone,
            description
          })
          .then(tenant => {
            if (tenant.status !== 201) {
              alert.show(t(`${tenant.error}`), {
                type: 'error'
              })
            } else {
              alert.show('proveedor añadido correctamente', {
                type: 'success'
              })
              history.push({
                pathname: '/tenants'
              })  
            }
          })
          .catch(err => {
            if (err.message === '409') {
              alert.show(t('Input already exists'), {
                type: 'error'
              })
            } else {
              alert.show(t('Unexpected error'), {
                type: 'error'
              })
            }
          })
      }  
    } else {
      if (id) {
        logic
          .updateDistributorTenant({
            distributorTenantId: id.toString(),
            distributorCode,
            distributorIdentity,
            distributorName,
            postalCodeId: geoarea,
            country,
            phone,
            alternatePhone,
            address,
            primaryContactId,
            salesStock: notifySalesStock,
            isUploadBlocked,
            toMonthBlocked: new Date(dateBlocked),
            description
           })
          .then(distributorTenant => {
            if (distributorTenant.status !== 200) {
              alert.show(t(`${distributorTenant.error}`), {
                type: 'error'
              })
            } else {
              alert.show(t('updateSuccesful'), {
                type: 'success'
              })
              history.push({
                pathname: '/distributor'
              })  
            }
          })
          .catch(err => {
            if (err.message === '409') {
              alert.show(t('Code/NIF already exists'), {
                type: 'error'
              })
            } else {
              alert.show(t('Unexpected Error'), {
                type: 'error'
              })
            }
          })
      } else {
        logic
          .insertOneDistributorTenant({
            distributorCode,
            distributorIdentity,
            distributorName,
            postalCodeId: geoarea,
            country,
            phone,
            alternatePhone,
            address,
            primaryContactId,
            salesStock: notifySalesStock,
            isUploadBlocked,
            toMonthBlocked: new Date(dateBlocked),
            description
          })
          .then(distributorTenant => {
            if (distributorTenant.status !== 200) {
              alert.show(t(`${distributorTenant.error}`), {
                type: 'error'
              })
            } else {
              alert.show(t('distributorCreated'), {
                type: 'success'
              })
              history.push({
                pathname: '/distributor'
              })  
            }
          })
          .catch(err => {
            if (err.message === '409') {
              alert.show('Input already exists', {
                type: 'error'
              })
            } else {
              alert.show('Unexpected Error', {
                type: 'error'
              })
            }
          })
      }
    }
    setButtonState(BUTTON_STATES.active)
  }

  const handleChangeCountry = async (event) => {
    const { name, value } = event.target
    setSelectedCountry(value)
    //await validateCountryAndPostalCode(undefined, value, 0)
    const isPostalCodeValid = await validateCountryAndPostalCode(undefined, value, 0)
    if (!isPostalCodeValid) {
      triggerValidation()
    } else {
      clearError(["country","postalCode", "geoarea"])
    }
    /* if (!isPostalCodeValid) {
      alert.show(t('invalidpostalcode'), {
        type: 'error'
      })
    } */
  }

  const handleChangePostalCode = async (event) => {
    const { name, value } = event.target
    const isPostalCodeValid = await validateCountryAndPostalCode(value)
    if (!isPostalCodeValid) {
      triggerValidation()
    } else {
      clearError(["country", "postalCode", "geoarea"])
    }
  }

  const handleChangeGeoArea = (event) => {
    const { name, value } = event.target
    const newPostalCode = allPostalCodes.filter(postalcode => { return parseInt(postalcode.postalcodeid) === parseInt(value) })
    setSelectedPostalCode(newPostalCode[0])
  }

  const validateCountryAndPostalCode = async (postalCode = selectedPostalCode.postalcode, country = selectedCountry, postalCodeId = 0) => {
    try {
      const postalCodeData = await logic.getPostalCodesCountry(postalCode === undefined ? '' : postalCode, country)
      if (postalCodeData && postalCodeData.data && postalCodeData.data[0]) {
        if (postalCodeId !== 0) {
          const originalPostalCode = postalCodeData.data.filter(postalcode => { return parseInt(postalcode.postalcodeid) === parseInt(postalCodeId) })
          setSelectedPostalCode(originalPostalCode[0])
        } else {
          setSelectedPostalCode(postalCodeData.data[0])
        }
        setAllPostalCodes(postalCodeData.data)
        return true
      } else {
        const newSelectedPostalCode = {
          postalcodeid: 0,
          postalcode: postalCode,
          country: country,
          region: '',
          province: '',
          population: ''
        }
        setSelectedPostalCode(newSelectedPostalCode)
        setAllPostalCodes([])
        return false
      }
    }
    catch (error) {
        alert.show(t('getpostalcodeerror'), {
          type: 'error'
        })
    }
  }

  return (
    <Box>
      {url.includes('distributor') ? <TabsDistributor highlight='distributor' /> : null}
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {tenantId || id
          ? `${t('edit')} ${distributor && distributor.distributorname}`
          : `${t('add')} ${url.includes('/tenants') ? t('provider') : t('distributor')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Level>
          <Field>
            <Label>{t('country')}</Label>
            <Control expanded>
            <Select.Container color='light' fullwidth>
                <Select
                  name='country'
                  ref={register({
                    required: true,
                    /* validate: value => validateCountryAndPostalCode(undefined, value) */
                  })}
                  value={watch('country') || (distributor && distributor.country)}
                  onChange={ (event) => handleChangeCountry(event)}
                >
                  <Select.Option value=''>{t('select')}</Select.Option>
                  {countries &&
                    countries.map((theCountry, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={theCountry.countrynamesp}
                          selected={theCountry.countrynamesp === distributor && distributor.country ? 'selected' : null}
                        >
                          {i18n.language === 'es' ? theCountry.countrynamesp : theCountry.countrynameen}
                        </Select.Option>
                      )
                    })
                  }
                </Select>
              </Select.Container>
              {errors.country &&
                errors.country.type === 'required' && (
                  <HelpError message={t('required')} />
              )}
              {errors.country &&
                errors.country.type === 'validate' && (
                 <HelpError message={t('invalidpostalcode')} />
              )}
            </Control>
          </Field>

          <Field>
            <Label>{t('postalCode')}</Label>

            <Control iconLeft iconRight>
              <Input
                defaultValue={distributor && distributor.postalcode}
                type='type'
                color='light'
                name='postalCode'
                onChange={ (event) => handleChangePostalCode(event)}
                ref={register({
                  maxLength: 12,
                  required: true,
                  validate: value => validateCountryAndPostalCode(value, undefined)
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'file-signature']} />
              </Icon>
            </Control>
            {errors.postalCode && 
              errors.postalCode.type === 'maxLength' && (
                <HelpError message={t('tooManyCharacters')} />
            )}  
            {errors.postalCode &&
              errors.postalCode.type === 'required' && (
                <HelpError message={t('required')} />
            )}
            {errors.postalCode &&
              errors.postalCode.type === 'validate' && (
                <HelpError message={t('invalidpostalcode')} />
            )}
          </Field>

          <Field>
            <Label>{t('geoarea')}</Label>
            <Control expanded>
            <Select.Container color='light' fullwidth>
                <Select
                  name='geoarea'
                  ref={register({
                    required: true
                  })}
                  defaultValue={distributor && distributor.postalcodeid}
                  value={watch('geoarea') || (selectedPostalCode && selectedPostalCode.postalcodeid)}
                  onChange={ (event) => handleChangeGeoArea(event)}
                >
                  {allPostalCodes &&
                    allPostalCodes.map((thePostalCode, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={thePostalCode.postalcodeid}
                          selected={thePostalCode.postalcodeid === distributor && distributor.postalcodeid ? 'selected' : null}
                        >
                          {`${thePostalCode.region}-${thePostalCode.province}-${thePostalCode.population}`}
                        </Select.Option>
                      )
                    })
                  }
                </Select>
              </Select.Container>
              {errors.geoarea &&
                errors.geoarea.type === 'required' && (
                  <HelpError message={t('appearswithvalidapostalcode')} />
              )}
            </Control>
          </Field>

          <Field>
            <Label>{t('CIF/NIF')}</Label>
            <Control iconLeft>
              <Input
                defaultValue={distributor && distributor.distributoridentity}
                type='text'
                color='light'
                name='distributorIdentity'
                ref={register({
                  required: true,
                  maxLength: 9,
                  validate: selectedCountry === 'España' ? value => validateSpanishId(value) : true // for the moment we only validate spanish fiscal ids
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'archive']} />
              </Icon>
            </Control>
            {errors.distributorIdentity &&
              errors.distributorIdentity.type === 'required' && (
                <HelpError message={t('required')} />
              )}
            {errors.distributorIdentity &&
              errors.distributorIdentity.type === 'maxLength' && (
                <HelpError message={t('tooManyCharacters')} />
              )}
            {errors.distributorIdentity &&
            errors.distributorIdentity.type === 'validate' && (
              <HelpError message={t('validSpanishIdPlease')} />
            )}
          </Field>
        </Level>
        
        <Field>
          <Label>{t('code')}</Label>
          <Control iconLeft>
            <Input
              defaultValue={distributor && distributor.distributorcode}
              type='text'
              color='light'
              name='distributorCode'
              ref={register({
                required: true,
                maxLength: 20
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'archive']} />
            </Icon>
          </Control>
          {errors.distributorCode &&
            errors.distributorCode.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          {errors.distributorCode &&
            errors.distributorCode.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>
        <Field>
          <Label>{t('name')}</Label>
          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.distributorname}
              type='type'
              color='light'
              name='distributorName'
              ref={register({
                maxLength: url.includes('distributor') ? 100 : 50,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.distributorName && 
            errors.distributorName.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.distributorName &&
            errors.distributorName.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field>
          <Label>{t('region')}</Label>
          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.region}
              value={selectedPostalCode && selectedPostalCode.region}
              type='type'
              color='light'
              name='region'
              /* ref={register({
                maxLength: 100,
                required: true
              })} */
              disabled="true"
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
        {/*   {errors.region && 
            errors.region.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.region &&
            errors.region.type === 'required' && (
              <HelpError message={t('appearswithvalidapostalcode')} />
            )} */}
        </Field>

        <Field>
          <Label>{t('provinceupper')}</Label>
          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.province}
              value={selectedPostalCode && selectedPostalCode.province}
              type='type'
              color='light'
              name='province'
/*               ref={register({
                maxLength: 100,
                required: true
              })}
 */              disabled="true"
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {/* {errors.province && 
            errors.province.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.province &&
            errors.province.type === 'required' && (
              <HelpError message={t('appearswithvalidapostalcode')} />
            )} */}
        </Field>

        <Field>
          <Label>{t('cityupper')}</Label>
          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.city}
              value={selectedPostalCode && selectedPostalCode.population}
              type='type'
              color='light'
              name='city'
/*               ref={register({
                maxLength: 250,
                required: true
              })}
 */              disabled="true"
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {/* {errors.city &&
            errors.city.type === 'required' && (
              <HelpError message={t('appearswithvalidapostalcode')} />
          )} */}
        </Field>

        <Field>
          <Label>{t('address')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.address}
              type='type'
              color='light'
              name='address'
              ref={register({
                maxLength: 256,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.address && 
            errors.address.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.address &&
            errors.address.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field>
          <Label>{t('phone')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.phone}
              type='type'
              color='light'
              name='phone'
              ref={register({
                maxLength: 15,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.phone && 
            errors.phone.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.phone &&
            errors.phone.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field>
          <Label>{t('alternatePhone')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.alternativephone}
              type='type'
              color='light'
              name='alternatePhone'
              ref={register({
                maxLength: 15,
                required: false
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.alternatePhone && 
            errors.alternatePhone.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
        </Field>

        {url.includes('distributor') && id && 
          <Field>
            <Label>{t('primaryContact')}</Label>
            <Control expanded>
              <Select.Container color='light' fullwidth>
                <Select
                  name='primaryContact'
                  value={watch('primaryContact') || (distributor && distributor.primarycontactid)}
                  ref={register({
                  })}
                >
                  <Select.Option value=''>{t('select')}</Select.Option>
                  {primaryContacts &&
                    primaryContacts.map((contact, index) => (
                      <Select.Option
                        key={index}
                        value={contact && contact.userid}
                      >
                        {contact && contact.username} ({contact && contact.useremail})
                      </Select.Option>
                    ))}
                </Select>
              </Select.Container>
              {errors.primaryContact &&
                 errors.primaryContact.type === 'required' && (
                  <HelpError message={t('required')} />
              )}
            </Control>
          </Field>
        }
        
        {url.includes('distributor') && 
          <Level>
            <Level.Item align="left">
              <Field>
                <Label>
                  <Checkbox
                    type='checkbox'
                    color='light'
                    name='isUploadBlocked'
                    ref={register}
                    onClick={(event) => setIsUploadedBlockedClicked(!isUploadedBlockedClicked)}
                    defaultChecked={distributor && distributor.isuploadblocked}
                  />
                      <strong style={{ margin: '5px' }}>
                      {t('isUploadBlocked')}
                      </strong>
                </Label>
              </Field>
            </Level.Item>
          </Level>
        }
        {url.includes('distributor') && isUploadedBlockedClicked &&
          <Level>
            <Level.Item align="left">
              <Field>
                <Label>{t('BlockTo')}</Label>
                <Control  >
                  <Input
                    defaultValue={distributor && distributor.tomonthblocked !== null ? `${new Date(distributor.tomonthblocked).getFullYear()}-${(new Date(distributor.tomonthblocked).getMonth()+1).toString().padStart(2, '0')}` : null}
                    max={MAXMONTHTOBLOCK[new Date().getMonth()]}
                    type='month'
                    color='light'
                    name='dateBlocked'
                    tooltip={t('infoBlocked')}
                    tooltipMultiline
                    tooltipPosition="right"
                    tooltipColor="info"
                    ref={register}
                  />
                </Control>
              </Field>
            </Level.Item>
          </Level>
        }

        {url.includes('distributor') &&
          <Field>
            <Label>
              <Checkbox
                type='checkbox'
                color='light'
                name='salesStock'
                ref={register}
                defaultChecked={watch('salesStock') || (distributor && distributor.salesstock)}
              />
                  <strong style={{ margin: '5px' }}>
                  {t('notifyIfRegistryNotSent')}

                  </strong>
            </Label>
          </Field>
        }

        <Field>
          <Label>{t('description')}</Label>
          <Control>
            <Textarea
              color='light'
              name='description'
              ref={register({
                maxLength: 1000,
                required: false
              })}
              defaultValue={distributor && distributor.description}
            />
          </Control>
          {errors.description && 
            errors.description.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
          )}
        </Field>

        <Field kind='group'>
          <Control>
            {tenantId || id ? (
              <Button state={buttonState} color='link'>{t('update')}</Button>
            ) : (
                <Button state={buttonState} color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to={url === '/tenants' ? '/tenants' : '/distributor'} type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}