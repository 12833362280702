/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Select, Title, Box, Field, Input, Control, Label, Button, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { FormError, HelpError } from '../../error'
import { logic } from '../../../logic'
import { useConfirmActionMessage } from '../../../logic/hooks'

export const AddEditPostalCodeAdmin = () => {
  const alert = useAlert()
  const [postalCode, setPostalCode] = useState()
  // Error handling
  const errorAction = useConfirmActionMessage()

  const history = useHistory()
  const { url } = useRouteMatch()
  const { postalCodeId } = useParams()
  const { register, handleSubmit, errors, watch } = useForm()
  const [selectedCountry, setSelectedCountry] = useState('')
  const [countries, setCountries] = useState([])
  const { t, i18n } = useTranslation()
  // Error handling
  const confirmAction = useConfirmActionMessage()

  useEffect(() => {
    (async function () {
      try {
        if (postalCodeId) {
          const postalCode = await logic.getOnePostalCodeById(parseInt(postalCodeId))
          setPostalCode(postalCode && postalCode.data && postalCode.data[0])
          setSelectedCountry(postalCode && postalCode.data && postalCode.data[0].country)
        }
        await getCountries() 
      } catch (error) {
          alert.show(error.message, {
            type: 'error'
          })
      }
    })()
    
  }, [])

  async function getCountries() {
    try {
      const sessionId = logic.sessionId.toString()
      const countries = await logic.getCountries(sessionId)
      let data = countries && countries.data 
      data = data.filter(item => {
        return item.active
      }) 
      const sortedCountries =
        data.sort((a, b) => {
          if (a.countrynamesp.toLowerCase() < b.countrynamesp.toLowerCase()) {
            return -1
          }
          if (a.countrynamesp.toLowerCase() > b.countrynamesp.toLowerCase()) {
            return 1
          }
          return 0
        })
      setCountries(sortedCountries)
    } catch (error) {
        alert.show(t('getcountrieserror'), {
          type: 'error'
        })
    }
  }

  const handleGoBackClick = () => {
    history.push('/postalcodes')
  }

  const onSubmitData = ({ postalcode, country, region, province, population }) => {
    errorAction.closeMessage()
    if (postalCodeId) {
      logic.updatePostalCodeAdmin({ postalCodeId, postalCode: postalcode, country, region, province, population })
      .then(updatedPostalCode => {
        if (updatedPostalCode.status !== 200) {
          throw Error(`${updatedPostalCode.status}`)
        }
        alert.show(t('postalCodeSuccessfullyUpdated'), {
          type: 'success'
        })
        history.push({
            pathname: '/postalcodes',
            state: '200'
          })
      })
      .catch(err => {
        if (err.message === '409') {
            alert.show(t('postalcodeexists'), {
            type: 'error'
          })
        } else {
           alert.show('Unexpected Error', {
            type: 'error'
          })
        }
      })
    } else {
      logic.insertPostalCodeAdmin({ postalCode: postalcode, country, region, province, population })
        .then(newPostalCode => {
          if (newPostalCode.status !== 200) {
            throw Error(`${newPostalCode.status}`)
          }
          alert.show(t('postalCodeSuccessfullyAdded'), {
            type: 'success'
          })
          history.push({
            pathname: '/postalcodes',
            state: '200'
          })
        })
        .catch(err => {
          if (err.message === '409') {
              alert.show(t('postalcodeexists'), {
               type: 'error'
            })
          } else {
             alert.show('Unexpected Error', {
              type: 'error'
          })
        }
        })
    }
  }

  const handleChangeCountry = (event) => {
    const { name, value } = event.target
    setSelectedCountry(value)
  }

  return (
    <Box>
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {postalCodeId ? `${t('edit')} ${postalCode && postalCode.postalcode} (${postalCode && postalCode.country})` : `${t('add')} ${t('postalcode')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field>
          <Label>{t('country')}</Label>
          <Control expanded>
          <Select.Container color='light' fullwidth>
              <Select
                name='country'
                ref={register({
                  required: true 
                })}
                value={watch('country') || (postalCode && postalCode.country)}
                onChange={ (event) => handleChangeCountry(event)}
              >
                <Select.Option value=''>{t('select')}</Select.Option>

                {countries &&
                  countries.map((theCountry, index) => {
                    return (
                      <Select.Option
                        key={index}
                        value={theCountry.countrynamesp}
                        selected={theCountry.countrynamesp === postalCode && postalCode.country ? 'selected' : null}
                      >
                        {i18n.language === 'es' ? theCountry.countrynamesp : theCountry.countrynameen}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Select.Container>
            {errors.country && errors.country.type === 'required' && (<HelpError message={t('required')} />)}
          </Control>
        </Field>
        <Field>
          <Label>{t('region')}</Label>
          <Control iconLeft>
            <Input 
              type='text' 
              name='region' 
              ref={register({ required: true, maxLength: 100 })} 
              color='light' 
              defaultValue={postalCode && postalCode.region}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.region && errors.region.type === 'required' && (<HelpError message={t('required')} />)}
          {errors.region && errors.region.type === 'maxLength' && (<HelpError message={t('tooManyCharacters')} />)}
        </Field>
        <Field>
          <Label>{t('provinceupper')}</Label>
          <Control iconLeft>
            <Input 
              type='text' 
              name='province' 
              ref={register({ required: true, maxLength: 100 })} 
              color='light' 
              defaultValue={postalCode && postalCode.province}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.province && errors.province.type === 'required' && (<HelpError message={t('required')} />)}
          {errors.province && errors.province.type === 'maxLength' && (<HelpError message={t('tooManyCharacters')} />)}
        </Field>
        <Field>
          <Label>{t('population')}</Label>
          <Control iconLeft>
            <Input 
              type='text' 
              name='population' 
              ref={register({ required: true, maxLength: 250 })} 
              color='light' 
              defaultValue={postalCode && postalCode.population}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.population && errors.population.type === 'required' && (<HelpError message={t('required')} />)}
          {errors.population && errors.population.type === 'maxLength' && (<HelpError message={t('tooManyCharacters')} />)}
        </Field>
        <Field>
          <Label>{t('postalcode')}</Label>
          <Control iconLeft>
            <Input 
              type='text' 
              name='postalcode' 
              ref={register({ required: true, maxLength: 12 })} 
              color='light' 
              defaultValue={postalCode && postalCode.postalcode}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.postalcode && errors.postalcode.type === 'required' && (<HelpError message={t('required')} />)}
          {errors.postalcode && errors.postalcode.type === 'maxLength' && (<HelpError message={t('tooManyCharacters')} />)}
        </Field>
        <Field kind='group'>
          <Control>
            {postalCodeId ? (
              <Button color='link'>{t('update')}</Button>
            ) : (
                <Button color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/postalcodes' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
