import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, Level, Content, Label, Button } from 'rbx'
import { logic } from '../../../logic'
import { Multiselect } from 'multiselect-react-dropdown'
import { Modal, Icon } from 'antd'
import { useAlert } from 'react-alert'
import { AdsContext } from "../../../contexts/adsContext"

const currentYear = new Date().getFullYear()
const fromYear = 2021
const toYear = new Date(currentYear, 11).getFullYear()

function YearForm ({ date, onChange, hasRegistries }) {
  const { t } = useTranslation()

  const [yearState, setYearState] = useState(new Date().getMonth() > 0 ? new Date().getFullYear() : new Date().getFullYear() - 1)
  const [visible, setVisible] = useState(false)
  const [year, setYear] = useState()

  const years = []
  for (let i = fromYear; i <= toYear; i += 1) {
    years.push(i)
  }

  const handleChange = function handleChange (e) {
    const { year } = e
    if (year && year) {
      setYearState(year)
      onChange(year)
    }
    setVisible(false)
  }

  const handleShowModal = (event) => {
    const { year } = event.target.form
    setYear(year.value)
    if (yearState && hasRegistries){
      setVisible(true)
    } else {
      handleChange({ year: year.value })
    }
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <form className='DayPicker-Caption'>
      <Modal
        title={t('Year change')}
        visible={visible}
        onOk={()=> handleChange({ year: year })}
        onCancel={handleCancel}>
          {t('Data will be lost in case you change the year')}
      </Modal>
      <Select.Container>
        <Select name='year' onChange={handleShowModal} defaultValue='' value={yearState}>
          <Select.Option value=''>
            {t('select')} {t('year')}
          </Select.Option>
          {years.map((year, index) => {
            return (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            )
          })}
        </Select>
      </Select.Container>
    </form>
  )
}

export const YearPicker = props => {
  const styleSelectorDistributors = {
    chips: { background: '#6699cc', 'word-wrap': 'break-word ' },
    searchBox: { 'border-radius': '2%' },
    multiselectContainer: { width: '60vh', '-webkit-appearance': 'none' },
    inputField: {
      autocomplete: "off"
    },
    option: { // To change css for dropdown options
      'background-color': 'white',
      color: 'black'
    },
    groupHeading: {
      display: 'none'
    }
  }
  const [selectedYear, setSelectedYear] = useState(new Date().getMonth() > 0 ? new Date().getFullYear() : new Date().getFullYear() - 1)
  const [currentTenant, setCurrentTenant] = useState()
  const [distributorsFromTenant, setDistributorsFromTenant] = useState()
  const [tenantsFromDistributor, setTenantsFromDistributor] = useState()
  const [agreementsFromTenant, setAgreementsFromTenant] = useState()
  const { t } = useTranslation()
  const [selectedValues, setSelectedValues] = useState([{ distributorname: t('Todos los distribuidores')}])
  const [selectedDistributors, setSelectedDistributors] = useState([{ distributorname:  t('Todos los distribuidores')}])
  const [selectedValuesTypes, setSelectedValuesTypes] = useState([{ agreementtypename: t('Todos los negocios')}])
  const [selectedTypes, setSelectedTypes] = useState([{ agreementtypename:  t('Todos los negocios')}])
  const [isDownloadActive, setIsDownloadActive] = useState(false)
  const { fetchAllTenantAgreementsTypes, fetchAllDistributorTenantObjectives, 
          fetchAllDistributorObjectives, fetchAllAdminTables } = useContext(AdsContext)
  
  // flag to indicate that the chech button is validating (loading)
  const [isLoadingValidation, setIsLoadingValidation] = useState('active')
  const [isLoadingValidationDownload, setIsLoadingValidationDownload] = useState('active')
  const alert = useAlert()

  useEffect(() => {
    (async function () {
      try {
          if (logic.isTenant || logic.isAdmin) {
            await fetchAllDistributorTenantObjectives()
          } else {
            await fetchAllDistributorObjectives()
          }
        if (logic.isTenant) {
          const distributors = await logic.getDistributors()
          const data = distributors.data
          const sortedData = data.sort((a,b) => {
            if (a.distributorname < b.distributorname) {
              return -1
            }
            if (a.distributorname > b.distributorname) {
              return 1
            }
            return 0
          })
          sortedData.unshift({ distributorname: t('Todos los distribuidores') })
          setDistributorsFromTenant(sortedData)
        } else {
          let tenantsIdsWithAGRs = []
          let tenantsWithAGRs = []
          const allTables = await fetchAllAdminTables()   
          tenantsIdsWithAGRs = allTables.filter(table => { return table.active && table.tableoftablesname === 'AGR' && table.tableoftablesdata.split('###')[0] === 'DATA' })
                                     .map(table => { return table.tableoftablesdata.split('###')[1] })
          const tenants = await logic.getTenantsFromDistributor()
          if (tenants && tenants.data) {
            for (let index = 0; index < tenants.data.length; index++) {
              for (let i = 0; i < tenantsIdsWithAGRs.length; i++) {
                if (tenants.data[index].tenantid.toString() === tenantsIdsWithAGRs[i]) {
                  tenantsWithAGRs.push(tenants.data[index])
                }
              }
            }
          }
          
          setTenantsFromDistributor(tenantsWithAGRs)
          if (tenantsWithAGRs.length === 1) {
            await takeAgreementsTypes(tenantsWithAGRs[0].tenantid.toString())
            setCurrentTenant(tenantsWithAGRs[0].tenantid.toString())
          }
        }
      } catch (error) {
        console.log("Error in useEffect taking agreements")
        throw Error(error)
      }
    })()
  }, [])

  useEffect (() => {
    (async function () {
      try {
        if (logic.isTenant) {
          console.log("I'm tenant")
          await takeAgreementsTypes(logic.tenantId.toString())
        }
      } catch (error) {
        console.log("Error in useEffect taking types of agreements")
        throw Error(error)
      }
    })()
  }, [])

  // If both year and tenant have value, send the data do parent component
  useEffect(() => {
    // Look for currentTenant within 
    const tenantSelected = tenantsFromDistributor && tenantsFromDistributor.find( tenant => tenant.tenantid == currentTenant)
    props.doYearPick({ year: selectedYear, tenantId: currentTenant, types: selectedTypes, tenantName: tenantSelected && tenantSelected.tenantname })
  }, [currentTenant, selectedYear, selectedTypes])

  const handleYearChange = value => {
    setIsDownloadActive(false)
    setSelectedYear(value)
  }

  const takeAgreementsTypes = async (tenant) => {
    const allTenantAgreementsTypes = await fetchAllTenantAgreementsTypes(tenant)

    const sortedData = allTenantAgreementsTypes
                                  .filter(agreementType => {
                                      if (agreementType.active) return agreementType
                                  })
                                  .sort((a,b) => {
                                      if (a.agreementtypename < b.agreementtypename) {
                                        return -1
                                      }
                                      if (a.agreementtypename > b.agreementtypename) {
                                        return 1
                                      }
                                      return 0
                                  })
    sortedData.unshift({ agreementtypename: t('Todos los negocios') })
    setAgreementsFromTenant(sortedData)
  }

  const handleTenantChange = async (event) => {
    try {
      if (!event.target.value) return
      const newTenant = event.target.value
      await takeAgreementsTypes(newTenant)
      setCurrentTenant(newTenant)
    } catch (error) {
      console.log("Error getting agreement types within useEffect:", error)
      throw Error(error)
    }
  }

  const handleSelectDistributor = (selectedDistributors, selectedItem) => {
    setIsDownloadActive(false)
    const  indexOfAll = selectedDistributors
                                     .map(distributor => distributor.distributorname)
                                     .indexOf(t('Todos los distribuidores')) 
    if (selectedDistributors.length  > 1 && indexOfAll !== -1 && selectedItem.distributorname !== t('Todos los distribuidores')) {
      selectedDistributors.splice(indexOfAll, 1) 
    }
    if (selectedDistributors.length  > 1 && selectedItem.distributorname === t('Todos los distribuidores')) {
      selectedDistributors.splice(0, selectedDistributors.length - 1)
    } 
    setSelectedDistributors(selectedDistributors)
  }

  const handleSelectType = (selectedTypes, selectedItem) => {
    setIsDownloadActive(false)
    const  indexOfAll = selectedTypes
                                     .map(type => type.agreementtypename)
                                     .indexOf(t('Todos los negocios')) 
    if (selectedTypes.length  > 1 && indexOfAll !== -1 && selectedItem.agreementtypename !== t('Todos los negocios')) {
      selectedTypes.splice(indexOfAll, 1) 
    }
    if (selectedTypes.length  > 1 && selectedItem.agreementtypename === t('Todos los negocios')) {
      selectedTypes.splice(0, selectedTypes.length - 1)
    } 
    setSelectedTypes([...selectedTypes])
  }

  const handleRemoveDistributor = (selectedDistributors, selectedItem) => {
    setIsDownloadActive(false)
    setSelectedDistributors(selectedDistributors)
  }

  const handleRemoveType = (selectedTypes, selectedItem) => {
    setIsDownloadActive(false)
    setSelectedTypes([...selectedTypes])
  }

  const handleRetrieveSalesData = () => {
    if(!selectedDistributors || !selectedDistributors.length || !selectedYear){
      setIsDownloadActive(false)
      return alert.show(t('Please, choose at least one distributor and a year'), {
        type:
          'error'
      })
    }
    if (selectedYear && selectedDistributors) {
      setIsDownloadActive(true)
      props.doYearPick({ year: selectedYear, tenantId: null, types: selectedTypes, tenantName: null, distributors: selectedDistributors })
    }
  }

  const handleDownloadData = async () => {
    try {
      if(!selectedDistributors || !selectedDistributors.length || !selectedYear  ){
        setIsDownloadActive(false)
        return alert.show(t('Please, choose at least one distributor and a year'), {
          type:
            'error'
        })
      }
      setIsLoadingValidationDownload('loading')
      await props.downloadData()
      setIsLoadingValidationDownload('active')
      alert.show(t('report succesfully retrieved'), { type: 'success' })
    } catch (error) {
      console.log("Error while downloading all the distributors' tenant sales report")
      throw Error(error)
    }
  }

  return (
    <>     
            {logic.isTenant ?
              <>
                <Level>
                  
                <Level.Item align="left">
                    <Level.Item textAlign="left">
                      <Select.Container color='dark'>
                        <Multiselect selectedValues={selectedValues} autocomplete="off" placeholder={t('Seleccionar Distribuidores')} options={distributorsFromTenant} displayValue='distributorname' onSelect={handleSelectDistributor} onRemove={handleRemoveDistributor} className='multiselectcontainer' style={styleSelectorDistributors} avoidHighlightFirstOption />
                      </Select.Container>
                    </Level.Item>
                    <Level.Item textAlign="right">
                      <Select.Container color='dark'>
                        <Multiselect selectedValues={selectedValuesTypes} autocomplete="off" placeholder={t('Seleccionar acuerdos')} options={agreementsFromTenant} displayValue='agreementtypename' onSelect={handleSelectType} onRemove={handleRemoveType} className='multiselectcontainer' style={styleSelectorDistributors} avoidHighlightFirstOption />
                      </Select.Container>
                    </Level.Item>
                  </Level.Item>
                  
                  <Level.Item>
                    <Content>
                      <Label>{t('year sales')}:</Label>
                        <YearForm
                          date={selectedYear}
                          onChange={handleYearChange}
                          hasRegistries={props.hasRegistries}
                        />
                    </Content>
                  </Level.Item>
                </Level>
                <Level>
                  <Level.Item>
                    <Button.Group>
                      <Button onClick={handleRetrieveSalesData} color='primary' state={isLoadingValidation}>
                        {t('See sales')}
                      </Button>
                      { isDownloadActive ? 
                      <Button onClick={handleDownloadData} color='warning' state={isLoadingValidationDownload}>
                        <Icon type='download' /> &nbsp; {t('Download sales')}
                      </Button> : null
                      }
                    </Button.Group>
                  </Level.Item>
                </Level>
              </>
            :
              <>
                <Level>
                  <Level.Item>
                    <Content>
                      <Label>{t('provider')}:</Label>
                      <Select.Container>
                        <Select
                          name='tenants'
                          onChange={handleTenantChange}
                          value={currentTenant}
                        >
                          <Select.Option value=''>
                            {t('choose')} {t('provider')}
                          </Select.Option>
                          {tenantsFromDistributor &&
                            tenantsFromDistributor.map((tenant, index) => {
                              return (
                                <Select.Option
                                  key={index}
                                  value={tenant && tenant.tenantid}
                                >
                                  {tenant && tenant.tenantname}
                                </Select.Option>
                              )
                            })}
                        </Select>
                      </Select.Container>
                    </Content>
                  </Level.Item>
                  <Level.Item align='left'>
                    <Select.Container color='dark'>
                      <Multiselect selectedValues={selectedValuesTypes} autocomplete="off" placeholder={t('Seleccionar acuerdos')} options={agreementsFromTenant} displayValue='agreementtypename' onSelect={handleSelectType} onRemove={handleRemoveType} className='multiselectcontainer' style={styleSelectorDistributors} avoidHighlightFirstOption />
                    </Select.Container>
                  </Level.Item>
                  <Level.Item>
                    <Content>
                      <Label>{t('year sales')}:</Label>
                        <YearForm
                          date={selectedYear}
                          onChange={handleYearChange}
                          hasRegistries={props.hasRegistries}
                        />
                    </Content>
                  </Level.Item>
                </Level>
              </>
            }
    </>
  )
}