/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Title, Box, Field, Input, Control, Label, Button, Icon } from 'rbx'
import { Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../../error'
import { logic } from '../../../logic'
import { useConfirmActionMessage } from '../../../logic/hooks'

export const AddEditCountryAdmin = () => {
  const alert = useAlert()
  const [country, setCountry] = useState()
  // Error handling
  const errorAction = useConfirmActionMessage()

  const { t } = useTranslation()
  const history = useHistory()
  const { countryId } = useParams()
  const { register, handleSubmit, errors } = useForm()
  // Error handling
  const confirmAction = useConfirmActionMessage()

  useEffect(() => {
    if (countryId) {
      logic.getOneCountryAdmin(countryId)
        .then(country => {
          setCountry(country && country.data && country.data[0])
        })
        .catch(err => {
          alert.show(err.message, {
            type: 'error'
          })
        })
    }
  }, [])

  const handleGoBackClick = () => {
    history.push('/countries')
  }

  const onSubmitData = async ({ nameInSpanish, nameInEnglish }) => {
    try {
      errorAction.closeMessage()
      if (countryId) {
        if (nameInSpanish !== country.countrynamesp || nameInEnglish !== country.countrynameen) {
          showConfirmSubmit({ countryId, countryNameSp: nameInSpanish, countryNameEn: nameInEnglish })
        } 
      } else {
        const updatedCountry = await logic.insertCountryAdmin({ countryNameSp: nameInSpanish, countryNameEn: nameInEnglish })
        if (updatedCountry.status !== 200) {
          throw Error(updatedCountry.status)
        }
        alert.show(t('countrySuccessfullyAdded'), {
          type: 'success'
        })
        history.push({
          pathname: '/countries',
          state: '200'
        })
      }
    } catch (error) {
      console.log("error: ", error)
      if (error.message === '409') {
        alert.show(t('countryAlreadyExists'), {
          type: 'error'
        })
      } else {
        alert.show('Unexpected Error', {
          type: 'error'
        })
      }
    }
  }

  const showConfirmSubmit = ({ countryId, countryNameSp: nameInSpanish, countryNameEn: nameInEnglish }) => {
      let isOkClicked = false
      Modal.confirm({
        title: t('Confirm country name change'),
        icon: <Icon type='exclamation-circle' />,
        content: <span dangerouslySetInnerHTML={{ __html: t('warningimpactchangingcountryname') }} />,
        onOk () {
          if (!isOkClicked) {
            isOkClicked = true
            handleOk({ countryId, countryNameSp: nameInSpanish, countryNameEn: nameInEnglish })
          }
        },
        onCancel () {
        }
      })
  }

  const handleOk = async ({ countryId, countryNameSp: nameInSpanish, countryNameEn: nameInEnglish }) => {
    try {
      const updatedCountry = await logic.updateCountryAdmin({ countryId, countryNameSp: nameInSpanish, countryNameEn: nameInEnglish })
      if (updatedCountry.status !== 200) {
        throw Error(`${updatedCountry.status}`)
      }
      alert.show(t('countrySuccessfullyUpdated'), {
        type: 'success'
      })
      history.push({
          pathname: '/countries',
          state: '200'
      })
    } catch (error) {
      console.log("error: ", error)
      if (error.message === '409') {
        alert.show(t('countryAlreadyExists'), {
          type: 'error'
        })
      } else {
        alert.show('Unexpected Error', {
          type: 'error'
        })
      }
    }
  }

  return (
    <Box>
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {countryId ? `${t('edit')} ${country && country.countrynamesp}` : `${t('add')} ${t('country')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field>
          <Label>{t('namesp')}</Label>
          <Control iconLeft>
            <Input 
              type='text' 
              name='nameInSpanish' 
              ref={register({ required: true, maxLength: 50 })} 
              color='light' 
              defaultValue={country && country.countrynamesp}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.nameInSpanish && errors.nameInSpanish.type === 'required' && (<HelpError message='Required' />)}
          {errors.nameInSpanish && errors.nameInSpanish.type === 'maxLength' && (<HelpError message={t('tooManyCharacters')} />)}
        </Field>
        <Field>
          <Label>{t('nameen')}</Label>
          <Control iconLeft>
            <Input 
              type='text' 
              name='nameInEnglish' 
              ref={register({ required: true, maxLength: 50 })} 
              color='light' 
              defaultValue={country && country.countrynameen} 
            />
              <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.nameInEnglish && errors.nameInEnglish.type === 'required' && (<HelpError message='Required' />)}
          {errors.nameInEnglish && errors.nameInEnglish.type === 'maxLength' && (<HelpError message={t('tooManyCharacters')} />)}
        </Field>

        <Field kind='group'>
          <Control>
            {countryId ? (
              <Button color='link'>{t('update')}</Button>
            ) : (
                <Button color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/countries' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
