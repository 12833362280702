/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import {
  Select,
  Textarea,
  Box,
  Title,
  Container,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon,
  Checkbox
} from 'rbx'
import { useAlert } from 'react-alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams, userouteMatch } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../../error'
import { logic } from '../../../logic'
import { useConfirmActionMessage } from '../../../logic/hooks'
import { SelectDistributors } from '../selectDistributors'
import { SelectTenants } from '../selectTenants'
import { SelectRole } from '../selectRole'
import { NOTIFICATIONS } from '../../../notifications'

export const BUTTON_STATES = { hovered: 'hovered', focused: 'focused', active: 'active', loading: 'loading' }
export const AddEditUserAdmin = () => {
  // productTenants
  const [user, setUser] = useState()
  const [userActive, setUserActive] = useState()
  const [roleName, setRoleName] = useState()
  const [originalRoleName, setOriginalRoleName] = useState()
  const [originalTenantId, setOriginalTenantId] = useState()
  const [originalDistributorId, setOriginalDistributorId] = useState()
  const [antiRoleName, setAntiRoleName] = useState()
  const [selectedRole, setSelectedRole] = useState()
  const [distributorId, setDistributorId] = useState()
  const [tenantId, setTenantId] = useState()
  const [tenantName, setTenantName] = useState()

  const confirmAction = useConfirmActionMessage()
  // Button request loading
  const [buttonState, setButtonState] = useState(BUTTON_STATES.active)
  const alert = useAlert()
  const { t } = useTranslation()
  const history = useHistory()
  const { userId } = useParams()
  const { register, handleSubmit, errors, watch } = useForm()
  
  useEffect(() => {
    
    function loadUserId () {
      logic.getUserAdmin({ userId })
        .then(user => {
          if (user.status !== 200) {
            alert('network error')
          }
          setUser(user && user.data && user.data[0])
          if (user.data[0].rolename === 'Tenant') {
            setAntiRoleName('Distributor')
          }
          if (user.data[0].rolename === 'Distributor') {
            setAntiRoleName('Tenant')
          }
          setOriginalRoleName(user.data[0].rolename)
          setRoleName(user.data[0].rolename)
          setOriginalTenantId(user.data[0].tenantid)
          setOriginalDistributorId(user.data[0].distributorid)
          setUserActive(user.data[0].active)
        })
    }
    if (userId) {
      loadUserId()
    }
  }, [])

  const handleGoBackClick = () => {
    history.push('/users')
  }

  const onSubmitData = data => {
    setButtonState(BUTTON_STATES.loading)
    const { email, primaryContact } = data
    // we destructure as let because we wil be passing the city and province name, not id
    confirmAction.closeMessage()
    if (userId) {
      if (originalRoleName === 'Tenant' && roleName !== originalRoleName) {
        logic
          .updateUserTenantAdmin({
            userId: userId && userId.toString(),
            downgradeToDistributor: true,
            newDistributorId: distributorId && distributorId.toString(),
            newTenantId: tenantId && tenantId.toString(),
            tenantId: originalTenantId && originalTenantId.toString()
          })
          .then(user => {
            if (user.status !== 200) {
              throw Error(`${user.status}`)
            }
            alert.show(t('updateSuccesful'), {
              type: 'success'
            })
            history.push({
              pathname: '/users'
            })
          })
          .catch(err => {
            if (err.message === '409') {
              alert.show(t('Input already exists'), {
                type: 'error'
              })
            } else {
              alert.show(t('Unexpected error'), {
                type: 'error'
              })
            }
          })
      }
      if (originalRoleName === 'Tenant' && roleName === originalRoleName && tenantId !== originalDistributorId.tenantid) {
        logic
          .updateUserTenantAdmin({
            userId: userId && userId.toString(),
            downgradeToDistributor: false,
            newDistributorId: null,
            newTenantId: tenantId && tenantId.toString(),
            tenantId: originalTenantId && originalTenantId.toString()
          })
          .then(user => {
            if (user.status !== 200) {
              throw Error(`${user.status}`)
            }
            alert.show(t('updateSuccesful'), {
              type: 'success'
            })
            history.push({
              pathname: '/users'
            })
          })
          .catch(err => {
            if (err.message === '409') {
              alert.show(t('Input already exists'), {
                type: 'error'
              })
            } else {
              alert.show(t('Unexpected error'), {
                type: 'error'
              })
            }
          })
      }
      if (originalRoleName === 'Distributor' && roleName !== originalRoleName) {
        logic
          .updateUserDistributorAdmin({
            userId: userId && userId.toString(),
            upgradeToTenant: true,
            newDistributorId: distributorId && distributorId.toString(),
            distributorId: originalDistributorId && originalDistributorId.toString(),
            newTenantId: tenantId && tenantId.toString(),
            tenantId: originalTenantId && originalTenantId.toString()
          })
          .then(user => {
            if (user.status !== 200) {
              throw Error(`${user.status}`)
            }
            alert.show(t('updateSuccesful'), {
              type: 'success'
            })
            history.push({
              pathname: '/users'
            })
          })
          .catch(err => {
            if (err.message === '409') {
              alert.show(t('Input already exists'), {
                type: 'error'
              })
            } else {
              alert.show(t('Unexpected Error'), {
                type: 'error'
              })
            }
          })
      }
      if (originalRoleName === 'Distributor' && roleName === originalRoleName && tenantId === originalTenantId && distributorId !== originalDistributorId) {
        logic
          .updateUserDistributorAdmin({
            userId: userId && userId.toString(),
            upgradeToTenant: false,
            newDistributorId: distributorId && distributorId.toString(),
            distributorId: originalDistributorId && originalDistributorId.toString(),
            newTenantId: false,
            tenantId: originalTenantId && originalTenantId.toString()
          })
          .then(user => {
            if (user.status !== 200) {
              throw Error(`${user.status}`)
            }
            alert.show(t('updateSuccesful'), {
              type: 'success'
            })
            history.push({
              pathname: '/users'
            })
          })
          .catch(err => {
            if (err.message === '409') {
              alert.show(t('Input already exists'), {
                type: 'error'
              })
            } else {
              alert.show(t('Unexpected Error'), {
                type: 'error'
              })
            }
          })
      }
      if (originalRoleName === 'Distributor' && roleName === originalRoleName && tenantId !== originalTenantId && distributorId !== originalDistributorId) {
        logic
          .updateUserDistributorAdmin({
            userId: userId && userId.toString(),
            upgradeToTenant: false,
            newDistributorId: distributorId && distributorId.toString(),
            distributorId: originalDistributorId && originalDistributorId.toString(),
            newTenantId: tenantId && tenantId.toString(),
            tenantId: originalTenantId && originalTenantId.toString()
          })
          .then(user => {
            if (user.status !== 200) {
              throw Error(`${user.status}`)
            }
            alert.show(t('updateSuccesful'), {
              type: 'success'
            })
            history.push({
              pathname: '/users'
            })
          })
          .catch(err => {
            if (err.message === '409') {
              alert.show(t('Input already exists'), {
                type: 'error'
              })
            } else {
              alert.show(t('Unexpected error'), {
                type: 'error'
              })
            }
          })
      }
    } 
    if (user && !userActive) {
      logic
        .updateInactiveUserAdmin({
          userIdToUpdate: userId && userId.toString(),
          distributorId: distributorId,
          tenantId: tenantId,
          tenantName: tenantName,
          roleName: roleName
        })
        .then(user => {
          if (user.status !== 201) {
            throw Error(user.status)
          }
          alert.show(t('user updated Succesfully'), {
            type: 'success'
          })
          history.push({
            pathname: '/users'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show(t('Input already exists'), {
              type: 'error'
            })
          } else {
            alert.show(t('Unexpected error'), {
              type: 'error'
            })
          }
        })
    } 
    if (!userId) {
      logic
        .addUserAdmin({
          userEmail: email,
          distributorId: distributorId,
          tenantId: tenantId,
          tenantName: tenantName,
          roleName: roleName
        })
        .then(user => {
          if (user.status !== 201) {
            throw Error(user.status)
          }
          alert.show(t('user added succesfully'), {
            type: 'success'
          })
          history.push({
            pathname: '/users'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            if (err.message === '409') {
              alert.show(t('Input already exists'), {
                type: 'error'
              })
            } else {
              alert.show(t('Unexpected error'), {
                type: 'error'
              })
            }
        }
      })
    }
    setButtonState(BUTTON_STATES.active)
  }
  const handleSelectDistributor = value => {
    setDistributorId(value)
  }
  const handleSelectTenant = value => {
    setTenantId(null)
    setTenantId(value.split("&")[0])
    setTenantName(value.split("&")[1])
    setDistributorId(null)
  }
  const handleSelectRole = value => {
    setRoleName(value)
  }

  return (
    <Box>
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {userId
          ? `${t('edit')} ${user && user.useremail}`
          : `${t('add')} ${t('user')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData, tenantId, distributorId)}>
        <Field>
          <Control>
            <SelectRole doSelectRole={handleSelectRole} role={originalRoleName} antiRole={antiRoleName} />
          </Control>
        </Field>
        {roleName &&
          <Field>
            <SelectTenants doSelectTenant={handleSelectTenant} tenantIdOriginal={originalTenantId} />
          </Field>}
        {roleName && roleName === 'Distributor' && originalTenantId &&
          <Field>
            <Control>
              <SelectDistributors doSelectDistributor={handleSelectDistributor} originalDistributorId={originalDistributorId} tenantId={tenantId} originalTenantId={originalTenantId} />
            </Control>
          </Field>}
        {roleName && roleName === 'Distributor' && tenantId && !originalTenantId &&
          <Field>
            <Control>
              <SelectDistributors doSelectDistributor={handleSelectDistributor} originalDistributorId={originalDistributorId} tenantId={tenantId} originalTenantId={originalTenantId} />
            </Control>
          </Field>}
        <Field>
          <Control iconLeft>
            <Input
              name='email'
              ref={register({
                required: !userId
              })}
              color='light'
              type='email'
              placeholder={(user && user.useremail) || t('email')}
              autoFocus=''
              disabled={!!userId}
            />
            {errors.email && errors.email.type === 'required' && (
              <FormError
                showMessage
                type='danger'
                message='Required'
                closeButton={false}
              />
            )}
            {errors.email && errors.email.type === 'validate' && (
              <FormError message={t('email already taken')} />
            )}
            <Icon align='left'>
              <FontAwesomeIcon icon={['fas', 'envelope']} />
            </Icon>
          </Control>
        </Field>
        <Field kind='group'>
          <Control>
            {userId ? (
              <Button state={buttonState} color='link'>{t('update')}</Button>
            ) : (
                <Button state={buttonState} color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/users' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
