/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { Select, Box } from 'rbx'
import { TabsClients } from '../TabsClients'
import './ClientsPerDistributor.scss'
import { Table, Switch, Icon } from 'antd'
import { Column, Button, Level } from 'rbx'
import { useRouteMatch } from 'react-router-dom'
import { jsonToExcelDownloadCorrespondances } from '../../../logic/excelRenderer'
import { AUDITTRAILREPORTMSG } from '../../../config/config'

export const ClientsPerDistributor = () => {
  // i18n hook
  const { t } = useTranslation()
  const { url } = useRouteMatch()

  // main state
  const [selectedYear, setSelectedYear] = useState(new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear())

  // dataSource
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState('active')

  const columns = [
    {
      title: t('Distribuidor'),
      dataIndex: 'distributorname',
      key: 'distributorname',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Enero'),
      dataIndex: 'January',
      key: 'January',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Febrero'),
      dataIndex: 'February',
      key: 'February',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Marzo'),
      dataIndex: 'March',
      key: 'March',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Abril'),
      dataIndex: 'April',
      key: 'April',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Mayo'),
      dataIndex: 'May',
      key: 'May',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Junio'),
      dataIndex: 'June',
      key: 'June',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Julio'),
      dataIndex: 'July',
      key: 'July',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Agosto'),
      dataIndex: 'August',
      key: 'August',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Septiembre'),
      dataIndex: 'September',
      key: 'September',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Octubre'),
      dataIndex: 'October',
      key: 'October',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Noviembre'),
      dataIndex: 'November',
      key: 'November',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('Diciembre'),
      dataIndex: 'December',
      key: 'December',
      align: 'right',
      render: (text, record) => (record.distributorname === 'TOTAL' ? <b>{text}</b> : <p>{text}</p>),
    },
    {
      title: t('TotalDistributor'),
      dataIndex: 'totalDistributor',
      key: 'totalDistributor',
      align: 'right',
      render: (text) => <b>{text}</b>,
    },
  ]

  const currentYear = new Date().getFullYear()
  const fromYear = 2020
  const toYear = new Date(currentYear, 11).getFullYear()
  const years = []
  for (let i = toYear; i >= fromYear; i -= 1) {
    years.push(i)
  }

  // Fetch products when component mounts
  useEffect(() => {
    (async function () {
      let clientsData
      if (url === '/reports/newPerDistributor') {
        clientsData = await logic.getNewClientsPerDistributor(selectedYear)
      } else {
        clientsData = await logic.getTotalClientsPerDistributor(selectedYear)
      }
      let totalMonths = [
        {
          January: 0,
          February: 0,
          March: 0,
          April: 0,
          May: 0,
          June: 0,
          July: 0,
          August: 0,
          September: 0,
          October: 0,
          November: 0,
          December: 0,
          totalDistributor: 0, // its the total clients but due to the column name we leave it as totalDistributor
          distributorname: 'TOTAL',
          key: (clientsData && clientsData.data && clientsData.data.length) || 0,
          year: selectedYear,
        },
      ]
      let totalClients = 0
      const parametrized =
        clientsData &&
        clientsData.data &&
        clientsData.data.map((value, index) => {
          let totalDistributor = 0
          for (const property in value) {
            if (!value[property]) {
              value[property] = 0
            }
            if (property !== 'distributorname' && property !== 'year') {
              totalDistributor = totalDistributor + value[property];
              totalMonths[0][property] = totalMonths[0][property] + value[property];
              value[property] = value[property].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            }
          }
          totalClients = totalClients + totalDistributor
          return {
            key: index,
            ...value,
            totalDistributor: totalDistributor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          }
        })
      totalMonths[0].totalDistributor = totalClients
      for (const property in totalMonths[0]) {
        if (property !== 'distributorname' && property !== 'year' && property !== 'key') {
          totalMonths[0][property] = totalMonths[0][property].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        }
      }
      setDataSource(parametrized.concat(totalMonths))
    })()    
  }, [selectedYear, url])

  // components for the editable rows

  const handleSelectYear = (event) => {
    const year = event.target.value
    setSelectedYear(year)
  }

  const handleDownloadValues = () => {
    setLoading('loading')
    let clientsToDownload = JSON.parse(JSON.stringify(dataSource))

    clientsToDownload.map((clientToDownload) => {
      delete clientToDownload.key
      delete clientToDownload.year
      for (const property in clientToDownload) {
        if (property !== 'distributorname') clientToDownload[property] = clientToDownload[property].replace(/\./g, '')
      }
    })
    jsonToExcelDownloadCorrespondances(clientsToDownload, url === '/reports/newPerDistributor' ? 'Nuevos clientes' : 'Total clientes', url === '/reports/newPerDistributor' ? 'Nuevos clientes' : 'Total clientes')
    logic.insertAuditTrail({ module: '/auditTrail/add', message: url === '/reports/newPerDistributor' ? AUDITTRAILREPORTMSG('new clients per distributor', `${selectedYear} to ${selectedYear}`, logic.tenantId.toString()) : AUDITTRAILREPORTMSG('total clients per distributor', `${selectedYear} to ${selectedYear}`, logic.tenantId.toString()) })
    setLoading('active')
  };

  return (
    <>
      <Box id="clientsPerDistributor">
        <TabsClients highlight={url === '/reports/newPerDistributor' ? 'newPerDistributor' : 'totalPerDistributor'} />
        {logic.notCrossSales && <Switch checkedChildren={t('Sin vtas cruzadas')} disabled checked />}
        {!logic.notCrossSales && <Switch unCheckedChildren={t('Con vtas cruzadas')} disabled />}
        <br />
        <br/>
        <Level>
          <Level.Item align='left'>
            <Select.Container>
              <Select value={selectedYear} onChange={handleSelectYear}>
                {years.map((year, index) => {
                  return (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  )
                })}
              </Select>
            </Select.Container>
          </Level.Item>
          <Level.Item align='right'>
            <Button
              onClick={handleDownloadValues}
              style={{
                backgroundColor: '#538cc6',
                color: 'white'
              }}
              state={loading}
            >
              <Icon type="download" /> &nbsp; {t('Download values')}
            </Button>
          </Level.Item>
        </Level>
        <Column.Group>
          <Column>
            <Table pagination={false} dataSource={dataSource} columns={columns}></Table>
          </Column>
        </Column.Group>
      </Box>
    </>
  )
}