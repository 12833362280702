/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { logic } from '../../logic'
import {
  Title,
  Level,
  Field,
  Icon,
  Button,
  Table,
  Box
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../pagination'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useToggle, useConfirmActionMessage } from '../../logic/hooks'
import { Search } from '../filter/Search'
import { Modal } from '../modal'
import { ActionButtons } from '../actionButtons'
import './TenantsAdmin.scss'
import { mixTenantInfo } from '../../utils/mixTenantInfo'
import { MediaItem } from 'rbx/components/media/media-item'

export const TenantsAdmin = () => {
  const alert = useAlert()
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])
  // state inside the modal
  const [counter, setCounter] = useState(0)

  // state inside the number of users in a tenant
  const [tenantUsers, setTenantUsers] = useState([])

  // state inside the number of distributors in a tenant
  const [numberDistributors, setNumberDistributors] = useState([])

  // state inside the number of distributors in a tenant
  const [numberUsersDistributors, setNumberUsersDistributors] = useState([])

  // main state
  const [tenants, setTenants] = useState()

  // tenants that are searched for
  const [searchTenants, setSearchTenants] = useState([])

  // Error message hook
  const errorAction = useConfirmActionMessage()

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // Fetch tenants when component mounts
  useEffect(() => {
    logic
      .getTenantsAdmin()
      .then(tenants => {
        const sortedTenants = tenants && tenants.data.sort((a, b) => {
          if (a.tenantname < b.tenantname) {
            return -1
          }
          if (a.tenantName > b.tenantName) {
            return 1
          }
        })
        setTenants(sortedTenants)
        setSearchTenants(sortedTenants)
      })
  }, [counter])
  useEffect(() => {
    logic
      .getCountTenantsAdmin()
      .then(tenantsInfo => {
        if (tenants) {
          const mix = mixTenantInfo.mixTenantInfo(tenants, tenantsInfo.data)
          setSearchTenants(mix)
        }
      })
      .catch(err => {
        window.alert(err.message)
      })
  }, [tenants])

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.tenantidentity}/${element && element.tenantid}/${element && element.postalcodeid}`
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    const { tenantid } = element
    logic
      .deleteTenantAdmin({ tenantId: tenantid.toString() })
      .then(item => {
        if (item.status !== 200) {
          alert.show(t(item.error), {
            type: 'error'
          })
        }
        setCounter(previousState => previousState + 1)
        toggle()
      })
      .catch(err => {
        console.error(err.message)
      })
      .catch(() => {
        toggle()
      })
  }

  const showRelatedItems = (event, item) => {
    setDeleteItem({ ...item })
    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const handleDoSearch = value => {
    const result = tenants.filter(tenant => {
      const values = Object.values(tenant)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.tenantname < b.tenantname) {
        return -1
      }
      if (a.tenantname > b.tenantname) {
        return 1
      }
    })
    setSearchTenants(sortedResult)
    return sortedResult
  }
  return (
    <Box id='tenants'>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteRelated')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.length > 7 &&
            related.slice(0, 7).map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related &&
            related.length !== 0 &&
            related.length <= 7 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, deleteItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}
      <Level>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading class='tenant' colSpan='1'>
              {t('tenant name')}
            </Table.Heading>
            <Table.Heading class='tenant' colSpan='1'>
              {t('tenant Id')}
            </Table.Heading>
            <Table.Heading class='tenant' colSpan='1'>
              {t('status')}
            </Table.Heading>
            <Table.Heading class='tenant' colSpan='1'>
              {t('number of users')}
            </Table.Heading>
            <Table.Heading class='tenant' colSpan='1'>
              {t('number of distributors')}
            </Table.Heading>
            <Table.Heading class='tenant' colSpan='1'>
              {t('number of users in distributors')}
            </Table.Heading>
            <Table.Heading colSpan='3'>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchTenants &&
            searchTenants
              .slice(startPosition, endPosition)
              .map((item, index) => {
                return (
                  <Table.Row key={item.tenantcode + index}>
                    <Table.Cell>{item.tenantname}</Table.Cell>
                    <Table.Cell>{item.tenantidentity}</Table.Cell>
                    <Table.Cell>{item.active ? 'active' : 'inactive'}</Table.Cell>
                    <Table.Cell>{item.TenantUsers ? item.TenantUsers : 0}</Table.Cell>
                    <Table.Cell>{item.Distributors ? item.Distributors : 0}</Table.Cell>
                    <Table.Cell>{item.DistributorUsers ? item.DistributorUsers : 0}</Table.Cell>
                    <Table.Cell>
                      <ActionButtons
                        onEditClick={e => handleAddEditClick(e, item)}
                        onDeleteClick={e => showRelatedItems(e, item)}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          {!searchTenants && (
            <Table.Row>
              <Table.Heading colSpan='4'>{t('noDataAvailable')}</Table.Heading>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchTenants && searchTenants.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
