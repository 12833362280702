import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { TabsProduct } from '../tabsProduct'
import {
  Level,
  Button,
  Table,
  Box,
  Select
} from 'rbx'
import { Icon } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Search } from '../../filter/Search'
import { jsonToExcelDownloadCorrespondances } from '../../../logic/excelRenderer'
import { AUDITTRAILREPORTMSG } from '../../../config/config'

export const ProductValue = () => {

  // main state
  const [allProducts, setAllProducts] = useState()
  const [products, setProducts] = useState()

  // products that are searched for
  const [searchProducts, setSearchProducts] = useState([])

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()

  const [loading, setLoading] = useState('active')

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // React active or inactive
  const [dropdownState, setDropdownState] = useState('active')
  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()
  const [years, setYears] = useState()
  const [yearPrice, setYearPrice] = useState()

  useEffect(() => {
    logic.getProductPrices().then(products => {
      setAllProducts(products && products.data)
      const data = products && products.data
      const pricesYears = [...new Set(data.map(item => item.productpriceyear))]
      const pricesYearsFiltered = pricesYears.filter(item => {
        if (item !== null) return item
      }).sort()
      setYears(pricesYearsFiltered)
      if (pricesYearsFiltered) setYearPrice(pricesYearsFiltered[pricesYearsFiltered.length - 1])

      setProducts(data && data.filter(el => { return el.active && el.productpriceyear === pricesYearsFiltered[pricesYearsFiltered.length - 1]}))
      setSearchProducts(data && data.filter(el => { return el.active && el.productpriceyear === pricesYearsFiltered[pricesYearsFiltered.length - 1]}))
    })
  }, [])

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element && element.productpriceid !== null) {
      history.push({
        pathname: `${url}/edit/${element && element.productpriceid}/${element && element.producttenantid}`,
        state: element
      })
    } else {
      history.push({
        pathname: `${url}/add/0/${element && element.producttenantid || 0}`
      })
    }
  }

  const handleDoSearch = value => {
    const result = products.filter(product => {
      const newProduct = {
        producttenantcode: product.producttenantcode,
        productname: product.productname,
        productprice: product.productprice,
        productpricecurrency: product.productpricecurrency,
        productpricefromdate: product.productpricefromdate,
        productpriceyear: product.productpriceyear,
        productpricetodate: product.productpricetodate,
      }
      const values = Object.values(newProduct)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    setSearchProducts([...result])
    return result
  }

  const handleDropdownStateChange = (event) => {
    setDropdownState(event.target.value)
    if(event.target.value === 'active'){
      yearPrice === 0 ? setProducts(allProducts && allProducts.filter(el => { return el.active })) : setProducts(allProducts && allProducts.filter(el => { return el.active && parseInt(el.productpriceyear) === parseInt(yearPrice)}))
      yearPrice === 0 ? setSearchProducts(allProducts && allProducts.filter(el => { return el.active })) : setSearchProducts(allProducts && allProducts.filter(el => { return el.active && parseInt(el.productpriceyear) === parseInt(yearPrice)}))
    }

    if(event.target.value === 'inactive'){
      yearPrice === 0 ? setProducts(allProducts && allProducts.filter(el => { return !el.active })) : setProducts(allProducts && allProducts.filter(el => { return !el.active && parseInt(el.productpriceyear) === parseInt(yearPrice)}))
      yearPrice === 0 ? setSearchProducts(allProducts && allProducts.filter(el => { return !el.active })) : setSearchProducts(allProducts && allProducts.filter(el => { return !el.active && parseInt(el.productpriceyear) === parseInt(yearPrice)}))
    }
  }
 
  const handleYearChange = (e) => {
    const { year } = e.target.form
    setYearPrice(parseInt(year.value))
    parseInt(year.value) === 0 ? setProducts(allProducts && allProducts.filter(el => { return dropdownState === 'active' ? el.active : !el.active })) : setProducts(allProducts && allProducts.filter(el => { return dropdownState === 'active' ? el.active && parseInt(el.productpriceyear) === parseInt(year.value) : !el.active && parseInt(el.productpriceyear) === parseInt(year.value)}))
    parseInt(year.value) === 0 ? setSearchProducts(allProducts && allProducts.filter(el => { return dropdownState === 'active' ? el.active : !el.active })) : setSearchProducts(allProducts && allProducts.filter(el => { return dropdownState === 'active' ? el.active && parseInt(el.productpriceyear) === parseInt(year.value) : !el.active && parseInt(el.productpriceyear) === parseInt(year.value) }))
  }

  const handleDownloadValues = () => {
    setLoading('loading')
    let productsToDownload = JSON.parse(JSON.stringify(products))

    productsToDownload.map(productToDownload => {
      delete productToDownload.productpriceid
      delete productToDownload.producttenantid
      delete productToDownload.productpricefromdate
      delete productToDownload.productpricetodate
      delete productToDownload.tenantid  
      productToDownload.productprice !== null ? productToDownload.productprice = productToDownload.productprice.toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : productToDownload.productprice = null
    })
    jsonToExcelDownloadCorrespondances(productsToDownload, 'Valores', 'Valor productos') 
    logic.insertAuditTrail({ module: '/auditTrail/add', message: AUDITTRAILREPORTMSG(`${dropdownState} product values`, `${yearPrice} to ${yearPrice}`, logic.tenantId.toString()) })
    setLoading('active')
  }


  return (
    <Box>
      <TabsProduct highlight='values' />
      <Level>
        <Select.Container>
          <Select value={dropdownState} onChange={handleDropdownStateChange} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item>
          <form>
            <Select.Container>
              <Select name='year' onChange={handleYearChange} defaultValue='' value={yearPrice && yearPrice} >
                <Select.Option key='0000' value='0000'>
                  {t('allyears')}
                </Select.Option>
                {years && years.map((year, index) => {
                  return (
                    <Select.Option key={year} value={year}>
                      {t('year')} {year}
                    </Select.Option>
                  )
                })}
              </Select>
            </Select.Container>
          </form>
        </Level.Item>
        <Level.Item align='right'>
          <Button
            onClick={handleDownloadValues}
            style={{
              marginRight: 50,
              backgroundColor: '#538cc6',
              color: 'white'
            }}
            state={loading}
          >
            <Icon type='download' /> &nbsp; {t('Download values')}
          </Button>
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('code')}</Table.Heading>
            <Table.Heading>{t('name')}</Table.Heading>
            <Table.Heading>{t('value')}</Table.Heading>
            <Table.Heading>{t('currency')}</Table.Heading>
            <Table.Heading>{t('startdate')}</Table.Heading>
            <Table.Heading>{t('enddate')}</Table.Heading>
            <Table.Heading>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchProducts &&
            searchProducts.slice(startPosition, endPosition).map((item, key) => {
              return (
                <Table.Row key={key}>
                  <Table.Heading>{item.producttenantcode}</Table.Heading>
                  <Table.Cell>{item.productname}</Table.Cell>
                  <Table.Cell align='right'>{item.productprice !== null ? item.productprice.toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : item.productprice}</Table.Cell>
                  <Table.Cell>{item.productpricecurrency}</Table.Cell>
                  <Table.Cell>{item.productpricefromdate !== null ? `${new Date(item.productpricefromdate).getDate().toString().padStart(2, '0')}/${(new Date(item.productpricefromdate).getMonth()+1).toString().padStart(2, '0')}/${new Date(item.productpricefromdate).getFullYear()}` : null}</Table.Cell>
                  <Table.Cell>{item.productpricetodate !== null ? `${new Date(item.productpricetodate).getDate().toString().padStart(2, '0')}/${(new Date(item.productpricetodate).getMonth()+1).toString().padStart(2, '0')}/${new Date(item.productpricetodate).getFullYear()}` : null}</Table.Cell>
                  <Table.Cell>
                    {item.producttenantcode !== 'DESCONOCIDO' &&
                      <button
                        onClick={e => handleAddEditClick(e, item)}
                        className='button is-primary is-outlined is-small'
                      >
                        {item.productpriceid !== null ?
                          <Icon>
                            <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                          </Icon>
                         :
                          <Icon>
                            <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
                          </Icon>
                        }
                      </button>
                    }
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchProducts && searchProducts.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
