/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { logic } from '../../logic'
import { Level, Box, Title, Icon, Button, Table, Select, Image } from 'rbx'
import { Spin } from 'antd'
import { Modal } from '../modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useToggle } from '../../logic/hooks'
import { Search } from '../filter/Search'

export const PostalCodesAdmin = () => {
  const alert = useAlert()
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)
  const [openInactive, toggleInactive] = useToggle(false)
  
  const [activateInactivateItem, setActivateInactivateItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // main state, what the component will recieve
  const [postalCodes, setPostalCodes] = useState([])
  const [allPostalCodes, setAllPostalCodes] = useState([])

  // brands that are searched for. what will be actually seen in the table
  const [searchPostalCodes, setSearchPostalCodes] = useState([])

  const { t } = useTranslation()

  // Routing variables
  const history = useHistory()
  const { url } = useRouteMatch()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)
  // React active or inactive
  const [dropdownState, setDropdownState] = useState('active')
  const [isLoading, setIsLoading] = useState(true)
  const [countries, setCountries] = useState()
  const [country, setCountry] = useState('allCountries')

  useEffect(() => {
    async function fetchData() {
      await getAllPostalCodes()    
      hideSpinner()
    }
    fetchData()
  }, [])

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.postalcodeid}`
        //state: element
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    const id = `${element.postalcodeid}`
    logic
      .deletePostalCode({ postalCodeId: id })
      .then(postalCodesWoDeletedOne => {
        if (postalCodesWoDeletedOne.status !== 200) {
          throw Error(postalCodesWoDeletedOne.error)
        }
        const newPostalCodes = postalCodes.filter(postalCode => postalCode.postalcodeid !== postalCodesWoDeletedOne.data[0].postalcodeid)
        modifyProperty(allPostalCodes, postalCodesWoDeletedOne.data[0].postalcodeid)
        const sortedNewPostalCodes =
          newPostalCodes.sort((a, b) => {
            if (a.postalcode.toLowerCase() < b.postalcode.toLowerCase()) {
              return -1
            }
            if (a.postalcode.toLowerCase() > b.postalcode.toLowerCase()) {
              return 1
            }
            return 0
          })
         const sortedNewAllPostalCodes =
          allPostalCodes.sort((a, b) => {
            if (a.postalcode.toLowerCase() < b.postalcode.toLowerCase()) {
              return -1
            }
            if (a.postalcode.toLowerCase() > b.postalcode.toLowerCase()) {
              return 1
            }
            return 0
          })
        setPostalCodes(sortedNewPostalCodes)
        setSearchPostalCodes(sortedNewPostalCodes)
        setAllPostalCodes(sortedNewAllPostalCodes)
        alert.show(t('postalCodeInactivated'), { type: 'success' })
        toggle()
      })
      .catch(err => {
        console.log("err: ", err)
        alert.show(t('errorinactivating')+`. ${err}`, { type: 'error' })
        console.error(err.message)
        toggle()        
      })
  }

  const handleActivateItem = (event, element) => {
    const id = `${element.postalcodeid}`
    logic
      .activatePostalCode({ postalCodeId: id })
      .then(postalCodesWoActivatedOne => {
        if (postalCodesWoActivatedOne.status !== 200) {
          throw Error(postalCodesWoActivatedOne.error)
        }
        const newPostalCodes = postalCodes.filter(postalCode => postalCode.postalcodeid !== postalCodesWoActivatedOne.data[0].postalcodeid)
        const newAllPostalCodes = allPostalCodes.filter(postalCode => postalCode.postalcodeid !== postalCodesWoActivatedOne.data[0].postalcodeid)
        modifyProperty(allPostalCodes, postalCodesWoActivatedOne.data[0].postalcodeid)
        const sortedNewPostalCodes =
          newPostalCodes.sort((a, b) => {
            if (a.postalcode.toLowerCase() < b.postalcode.toLowerCase()) {
              return -1
            }
            if (a.postalcode.toLowerCase() > b.postalcode.toLowerCase()) {
              return 1
            }
            return 0
          })
         const sortedNewAllPostalCodes =
          allPostalCodes.sort((a, b) => {
            if (a.postalcode.toLowerCase() < b.postalcode.toLowerCase()) {
              return -1
            }
            if (a.postalcode.toLowerCase() > b.postalcode.toLowerCase()) {
              return 1
            }
            return 0
          })
        setPostalCodes(sortedNewPostalCodes)
        setSearchPostalCodes(sortedNewPostalCodes)
        setAllPostalCodes(sortedNewAllPostalCodes)
        alert.show(t('postalCodeActivated'), { type: 'success' })
        toggleInactive()
      })
      .catch(err => {
        alert.show(t('erroractivating')+`. ${err}`, { type: 'error' })
        console.error(err.message)
        toggleInactive()
      })
  }

  const modifyProperty = (arr, targetId) => {
    const targetObj = arr.find(obj => obj.postalcodeid === targetId);
    if (targetObj) {
        targetObj.active = !targetObj.active
    }
  }

  const showRelatedItems = (event, item) => {
    setActivateInactivateItem({ ...item })

    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const showRelatedItemsInactive = (event, item) => {
    setActivateInactivateItem({ ...item })
    
    // TODO set correct logic for related
    setRelated([])
    toggleInactive()
  }

  const handleDoSearch = value => {
    const result = postalCodes.filter(postalCode => {
      const values = Object.values(postalCode)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.postalcode.toLowerCase() < b.postalcode.toLowerCase()) {
        return -1
      }
      if (a.postalcode.toLowerCase() > b.postalcode.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchPostalCodes(sortedResult)
    return sortedResult
  }

  const handleDropdownStateChange = (event) => {
    setDropdownState(event.target.value)
    let data
    if(event.target.value === 'active'){
      country === 'allCountries' ? data = allPostalCodes && allPostalCodes.filter(el => { return el.active }) : data = allPostalCodes && allPostalCodes.filter(el => { return el.active && el.country === country })
    }

    if(event.target.value === 'inactive'){
      country === 'allCountries' ? data = allPostalCodes && allPostalCodes.filter(el => { return !el.active }) : data = allPostalCodes && allPostalCodes.filter(el => { return !el.active && el.country === country })
    }
    const sortedPostalCodes =
        data.sort((a, b) => {
          if (a.postalcode.toLowerCase() < b.postalcode.toLowerCase()) {
            return -1
          }
          if (a.postalcode.toLowerCase() > b.postalcode.toLowerCase()) {
            return 1
          }
          return 0
        })
    setPostalCodes(sortedPostalCodes)
    setSearchPostalCodes(sortedPostalCodes)
  }
  
  async function getAllPostalCodes(activeInactive = 'active') {
    try {
      const sessionId = logic.sessionId.toString()
      const postalCodes = await logic.getAllPostalCodes(sessionId)
      setAllPostalCodes(postalCodes && postalCodes.data)
      let data = postalCodes && postalCodes.data
      setCountries([...new Set(data.map(item => item.country))])
      data = data.filter(item => {
        return activeInactive === 'active' ? item.active : !item.active
      }) 
      const sortedPostalCodes =
        data.sort((a, b) => {
          if (a.postalcode.toLowerCase() < b.postalcode.toLowerCase()) {
            return -1
          }
          if (a.postalcode.toLowerCase() > b.postalcode.toLowerCase()) {
            return 1
          }
          return 0
        })
      setPostalCodes(sortedPostalCodes)
      setSearchPostalCodes(sortedPostalCodes)
    } catch (error) {
        alert.show(t('getpostalcodeserror'), {
          type: 'error'
        })
    }
  }

  const hideSpinner = () => {
    setIsLoading(false)
  }

  const handleCountryChange = (e) => {
    const { country } = e.target.form
    setCountry(country.value)
    let data
    country.value === 'allCountries' ? data = allPostalCodes && allPostalCodes.filter(el => { return dropdownState === 'active' ? el.active : !el.active }) : data = allPostalCodes && allPostalCodes.filter(el => { return dropdownState === 'active' ? el.active && el.country === country.value : !el.active && el.country === country.value })
    const sortedPostalCodes =
        data.sort((a, b) => {
          if (a.postalcode.toLowerCase() < b.postalcode.toLowerCase()) {
            return -1
          }
          if (a.postalcode.toLowerCase() > b.postalcode.toLowerCase()) {
            return 1
          }
          return 0
        })
    setPostalCodes(sortedPostalCodes)
    setSearchPostalCodes(sortedPostalCodes)
  }

  return (
    <Box>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, activateInactivateItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}
      {openInactive && (
        <Modal toggle={toggleInactive} open={openInactive}>
          <Title>{t('confirmActivate')}</Title>
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('activateSure')}</Title>
              <Button
                onClick={e => handleActivateItem(e, activateInactivateItem)}
                color='success'
              >
                {t('Activate')}
              </Button>
            </>
          )}
        </Modal>
      )}
      <Level>
        <Select.Container>
          <Select value={dropdownState} onChange={handleDropdownStateChange} disabled={isLoading} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} isDisabled={isLoading} />
        </Level.Item>
        <Level.Item>
          <form>
            <Select.Container>
              <Select name='country' onChange={handleCountryChange} defaultValue='' value={country && country} disabled={isLoading} >
                <Select.Option key='0' value='allCountries'>
                  {t('allcountries')}
                </Select.Option>
                {countries && countries.map((country, index) => {
                  return (
                    <Select.Option key={index} value={country}>
                      {country}
                    </Select.Option>
                  )
                })}
              </Select>
            </Select.Container>
          </form>
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      {isLoading !== false ?
          <Image.Container size='3by2'>
            <p><b>{t('loadingpostalcodes')}</b></p>
            <Spin size="large" />
          </Image.Container>
          :
          null
      }
      {isLoading === false ?
        <Table fullwidth narrow hoverable bordered striped>
          <Table.Head>
            <Table.Row>
              <Table.Heading>{t('Postal Code')}</Table.Heading>
              <Table.Heading>{t('country')}</Table.Heading>
              <Table.Heading>{t('region')}</Table.Heading>
              <Table.Heading>{t('provinceupper')}</Table.Heading>
              <Table.Heading>{t('population')}</Table.Heading>
              <Table.Heading>{t('action')}</Table.Heading>
            </Table.Row>
          </Table.Head>
              <Table.Body>
              {searchPostalCodes &&
                searchPostalCodes.slice(startPosition, endPosition).map(item => {
                  return (
                    <Table.Row
                      onDoubleClick={e => handleAddEditClick(e, item)}
                      key={item.postalcodeid}
                    >
                      <Table.Cell>{item.postalcode}</Table.Cell>
                      <Table.Cell>{item.country}</Table.Cell>
                      <Table.Cell>{item.region}</Table.Cell>
                      <Table.Cell>{item.province}</Table.Cell>
                      <Table.Cell>{item.population}</Table.Cell>
                      <Table.Cell>
                          <button onClick={e => handleAddEditClick(e, item)} className='button is-primary is-outlined is-small'>
                            <Icon>
                              <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                            </Icon>
                          </button>
                        {dropdownState === 'active' && 
                            <button
                              onClick={e => showRelatedItems(e, item)}
                              className='button is-danger is-outlined is-small'
                            >
                              <Icon>
                                <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                              </Icon>
                            </button>
                        }
                        {dropdownState === 'inactive' && 
                          <button
                            onClick={e => showRelatedItemsInactive(e, item)}
                            className='button is-success is-outlined is-small'
                          >
                            <Icon>
                              <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                            </Icon>
                          </button>
                        }
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
        </Table>
        : 
        null
      }
      <Pagination
        totalItems={searchPostalCodes && searchPostalCodes.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
        isDisabled={isLoading} 
      />
    </Box>
  )
}
